import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ResearchesInfo } from "../ResearchesCard/ResearchesCard";

import Footer from "../Footer/Footer";
import downlaodBtn from "./PDF1.webp";
import sessionBarSpacer from "./Group_200.webp";
import scrollToTopBtn from "./Group_193.webp";
import scrollToTopBtnInversed from "./Group_2357.webp";
import { Link as RsLink } from "react-scroll";
import Header2 from "../Header2/Header2";

import "./ResearchesSub.css";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import API from "../helper/ApiHelper";
import { height } from "@mui/system";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import React from "react";

function ResearchesSub({
  props,
  baseRoute,
}: {
  props: ResearchesInfo;
  baseRoute: string;
}) {
  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Section Bar Collapse Control
  const isPhone = window.matchMedia("(max-width: 768px)").matches;

  const location = useLocation();
  const currentUrl = location.pathname;
  const sections: { name: string; url: string }[] = [
    { name: props.info.researchItemTitle, url: "research-item-session" },
    { name: props.info.mainTitleTree2, url: "research-detail-session" },
    {
      name: props.info.mainTitleTree3,
      url: "research-theoretical-session",
    },
    {
      name: props.info.mainTitleTree4,
      url: "research-publication-session",
    },
  ];
  function handleOnClick(url: string) {
    if (currentUrl === url) {
      return;
    }
    if (url == "#") setBtnSrc(scrollToTopBtn);
    window.location.href = url;
  }
  const [btnSrc, setBtnSrc] = useState(scrollToTopBtn);
  function onMouseOverButton() {
    setBtnSrc(scrollToTopBtnInversed);
  }

  function onMouseOutButton() {
    setBtnSrc(scrollToTopBtn);
  }

  // Set colon based in language
  const language = useSelector((state: RootState) => state.language.language);
  const [colonLang, setColonLang] = useState(":");
  // const [currentWebUrl, setCurrentWebUrl] = useState(
  //   window.location.href.toLowerCase().startsWith("http://localhost:3000")
  //     ? window.location.href.split("3000").at(-1)
  //     : window.location.href.split("com").at(-1)
  // );
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "en") {
      dispatch(setEnglish());
      // console.log(
      //   "dispatched english",
      //   currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1]
      // );
    } else if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "ch") {
      dispatch(setChinese());
      // console.log(
      //   "dispatched chinese",
      //   currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1]
      // );
    }
    if (language === "zh_hant") {
      setColonLang(":");
      const elements = document.getElementsByClassName(
        "research-detail-session-title"
      );
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i] as HTMLElement;
        element.classList.add("research-detail-session-title-zh_hant");
        element.classList.remove("research-detail-session-title-en");
      }
      document.title = props.info.projectName + " - ACTuwise";
    } else {
      setColonLang(":");
      document.title = props.info.projectName + " - ACTuwise";
      const elements = document.getElementsByClassName(
        "research-detail-session-title"
      );
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i] as HTMLElement;
        element.classList.remove("research-detail-session-title-zh_hant");
        element.classList.add("research-detail-session-title-en");
      }
    }
  }, [language]);
  // console.log("props.info.pdfUrl: ", props.info.pdfUrl);

  // Controll section bar section visibility
  let visibleSection: { name: string; url: string }[] = [
    { name: props.info.researchItemTitle, url: "research-item-session" },
  ];

  const [researchDetailSession, setResearchDetailSession] =
    useState<Element | null>(null);
  const [researchAdditionalSession1, setResearchAdditionalSession1] =
    useState<Element | null>(null);
  const [researchAdditionalSession2, setResearchAdditionalSession2] =
    useState<Element | null>(null);

  useEffect(() => {
    const detailSession = document.querySelector(".research-detail-session");
    const additionalSession1 = document.querySelector(
      ".research-theoretical-session"
    );
    const additionalSession2 = document.querySelector(
      ".research-publication-session"
    );

    setResearchDetailSession(detailSession);
    setResearchAdditionalSession1(additionalSession1);
    setResearchAdditionalSession2(additionalSession2);
  }, []);
  if (
    researchDetailSession &&
    !researchDetailSession.classList.contains("displayNone")
  ) {
    // console.log("Hello World");
    visibleSection.push({
      name: props.info.mainTitleTree2,
      url: "research-detail-session",
    });
  }
  if (
    researchAdditionalSession1 &&
    !researchAdditionalSession1.classList.contains("displayNone")
  ) {
    visibleSection.push({
      name: props.info.mainTitleTree3,
      url: "research-theoretical-session",
    });
  }
  if (
    researchAdditionalSession2 &&
    !researchAdditionalSession2.classList.contains("displayNone")
  ) {
    visibleSection.push({
      name: props.info.mainTitleTree4,
      url: "research-publication-session",
    });
  }
  // console.log(visibleSection);

  const researchDtailSessionText = document.querySelector(
    "#research-detail-session-text"
  );
  if (
    researchDtailSessionText &&
    !researchDtailSessionText.classList.contains("displayNone")
  ) {
    // console.log("Hello World");
    const elememt = document.getElementsByClassName("");
  }
  useEffect(() => {
    if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "en") {
      dispatch(setEnglish());
      // console.log("dispatched english", currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] )
    } else if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "ch") {
      dispatch(setChinese());
      // console.log("dispatched chinese", currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] )
    }
  }, [language]);
  return (
    <div className="research-wrapper">
      <Header2 url={"/research/" + props.info.url} />
      <div id="research" className="research content-section">
        {/*Start of section bar*/}
        <div className="section-bar">
          <div className="section-bar-container">
            {visibleSection.map((section, index) => {
              return (
                <React.Fragment key={index}>
                  {isPhone ? (
                    <div className="section-bar-row">
                      <RsLink
                        activeClass="current"
                        to={section.url}
                        spy={true}
                        smooth={true}
                        duration={50}
                      >
                        <div className="section-bar-img"></div>
                      </RsLink>
                      {index + 1 < visibleSection.length && (
                        <div className="section-bar-spacer">
                          <img src={sessionBarSpacer} />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="section-bar-row">
                      <RsLink
                        activeClass="current"
                        to={section.url}
                        spy={true}
                        smooth={true}
                        duration={50}
                      >
                        <div className="section-bar-img"></div>
                        <div
                          className={`section ${
                            currentUrl === section.url ? "current" : ""
                          }`}
                          key={section.name}
                          style={{ height: "50px" }}
                        >
                          {section.name}
                        </div>
                      </RsLink>
                      {index + 1 < visibleSection.length && (
                        <div className="section-bar-spacer">
                          <img src={sessionBarSpacer} />
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="module-container">
          <div
            className={"research-item-session research-session"}
            id="research-item-session"
          >
            {/* <button onClick={() => {console.log(API.getPDF)}}>hello</button>
            <button onClick={() => {console.log(props.info.pdfUrl)}}>hello2</button> */}
            <p
              className={`session-title${
                props.info.pdfUrl == API.getPDF ? "" : " displayNoneTitle"
              }`}
              id="researchItemTitle"
            >
              {props.info.researchItemTitle}
            </p>
            <div
              className={`download-container ${
                props.info.pdfUrl == API.getPDF ? "displayNone" : ""
              }`}
            >
              <div className={"downloadBtn"}>
                <a
                  href={props.info.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={downlaodBtn} />
                </a>
              </div>
            </div>
            <div
              className={
                "research-item-session-content research-session-content"
              }
            >
              <div
                className={`research-item-session-row ${
                  props.info.projectName == "" ? "displayNone" : ""
                }`}
              >
                <p className={"research-item-session-title"}>
                  {props.info.projectNameTitle}
                  {colonLang}
                </p>
                {/* <p className={"research-item-colon"}>{colonLang}</p> */}
                <p className={"research-item-session-text"}>
                  {props.info.projectName}
                </p>
              </div>
              <div
                className={`research-item-session-row ${
                  props.info.duration == "" ? "displayNone" : ""
                }`}
              >
                <p className={"research-item-session-title"}>
                  {props.info.durationTitle}
                  {colonLang}
                </p>
                {/* <p className={"research-item-colon"}>{colonLang}</p> */}
                <p className={"research-item-session-text"}>
                  {props.info.duration}
                </p>
              </div>
              <div
                className={`research-item-session-row ${
                  props.info.target == "" ? "displayNone" : ""
                }`}
              >
                <p className={"research-item-session-title"}>
                  {props.info.targetTitle}
                  {colonLang}
                </p>
                {/* <p className={"research-item-colon"}>{colonLang}</p> */}
                <p className={"research-item-session-text"}>
                  {props.info.target}
                </p>
              </div>
              <div
                className={`research-item-session-row ${
                  props.info.introduction == "" ? "displayNone" : ""
                }`}
              >
                <p className={"research-item-session-title"}>
                  {props.info.introductionTitle}
                  {colonLang}
                </p>
                {/* <p className={"research-item-colon"}>{colonLang}</p> */}
                <p className={"research-item-session-text"}>
                  {props.info.introduction}
                </p>
              </div>
              <div
                className={`research-item-session-row ${
                  props.info.partnerOrganization == "" ? "displayNone" : ""
                }`}
              >
                <p className={"research-item-session-title"}>
                  {props.info.partnerOrganizationTitle}
                  {colonLang}
                </p>
                {/* <p className={"research-item-colon"}>{colonLang}</p> */}
                <p className={"research-item-session-text"}>
                  {props.info.partnerOrganization}
                </p>
              </div>
              <div
                className={`research-item-session-row ${
                  props.info.funding == "" ? "displayNone" : ""
                }`}
              >
                <p className={"research-item-session-title"}>
                  {props.info.fundingTitle}
                  {colonLang}
                </p>
                {/* <p className={"research-item-colon"}>{colonLang}</p> */}
                <p className={"research-item-session-text"}>
                  {props.info.funding}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`research-detail-session research-session ${
              props.info.title1 == "" &&
              (props.info.content1 == "" ||
                props.info.content1 == "<p></p>\n") &&
              props.info.title2 == "" &&
              (props.info.content2 == "" ||
                props.info.content2 == "<p></p>\n") &&
              props.info.title3 == "" &&
              (props.info.content3 == "" ||
                props.info.content3 == "<p></p>\n") &&
              props.info.title4 == "" &&
              (props.info.content4 == "" ||
                props.info.content4 == "<p></p>\n") &&
              props.info.title5 == "" &&
              (props.info.content5 == "" || props.info.content5 == "<p></p>\n")
                ? "displayNone"
                : ""
            }`}
            id="research-detail-session"
          >
            <p className="research-session-title">
              {props.info.mainTitleTree2}
            </p>
            <div
              className={
                "research-detail-session-content research-session-content"
              }
            >
              <div
                className={`research-detail-session-row ${
                  props.info.title1 == "" &&
                  (props.info.content1 == "" ||
                    props.info.content1 == "<p></p>\n")
                    ? "displayNone"
                    : ""
                }`}
              >
                <p
                  className={`research-detail-session-title ${
                    props.info.title1 == "" ? "displayNone" : ""
                  }`}
                >
                  {props.info.title1}
                </p>
                <div
                  className={`research-detail-session-text ${
                    props.info.content1 == "" ||
                    props.info.content1 == "<p></p>\n"
                      ? "displayNone"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: props.info.content1,
                  }}
                ></div>
              </div>
              <div
                className={`research-detail-session-row ${
                  props.info.title2 == "" &&
                  (props.info.content2 == "" ||
                    props.info.content2 == "<p></p>\n")
                    ? "displayNone"
                    : ""
                }`}
              >
                <p
                  className={`research-detail-session-title ${
                    props.info.title2 == "" ? "displayNone" : ""
                  }`}
                >
                  {props.info.title2}
                </p>
                <div
                  className={`research-detail-session-text ${
                    props.info.content2 == "" ||
                    props.info.content2 == "<p></p>\n"
                      ? "displayNone"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: props.info.content2,
                  }}
                ></div>
              </div>
              <div
                className={`research-detail-session-row ${
                  props.info.title3 == "" &&
                  (props.info.content3 == "" ||
                    props.info.content3 == "<p></p>\n")
                    ? "displayNone"
                    : ""
                }`}
              >
                <p
                  className={`research-detail-session-title ${
                    props.info.title3 == "" ? "displayNone" : ""
                  }`}
                >
                  {props.info.title3}
                </p>
                <div
                  className={`research-detail-session-text ${
                    props.info.content3 == "" ||
                    props.info.content3 == "<p></p>\n"
                      ? "displayNone"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: props.info.content3,
                  }}
                ></div>
              </div>
              <div
                className={`research-detail-session-row ${
                  props.info.title4 == "" &&
                  (props.info.content4 == "" ||
                    props.info.content4 == "<p></p>\n")
                    ? "displayNone"
                    : ""
                }`}
              >
                <p
                  className={`research-detail-session-title ${
                    props.info.title4 == "" ? "displayNone" : ""
                  }`}
                >
                  {props.info.title4}
                </p>
                <div
                  className={`research-detail-session-text ${
                    props.info.content4 == "" ||
                    props.info.content4 == "<p></p>\n"
                      ? "displayNone"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: props.info.content4,
                  }}
                ></div>
              </div>
              <div
                className={`research-detail-session-row ${
                  props.info.title5 == "" &&
                  (props.info.content5 == "" ||
                    props.info.content5 == "<p></p>\n")
                    ? "displayNone"
                    : ""
                }`}
              >
                <p
                  className={`research-detail-session-title ${
                    props.info.title5 == "" ? "displayNone" : ""
                  }`}
                >
                  {props.info.title5}
                </p>
                <div
                  className={`research-detail-session-text ${
                    props.info.content5 == "" ||
                    props.info.content5 == "<p></p>\n"
                      ? "displayNone"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: props.info.content5,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className={`research-theoretical-session research-session ${
              props.info.mainTitleTree3 == "" &&
              props.info.subTitleTree3 == "" &&
              (props.info.contentTree3 == "" ||
                props.info.contentTree3 == "<p></p>\n")
                ? "displayNone"
                : ""
            }`}
            id="research-theoretical-session"
          >
            <p
              className={`session-title ${
                props.info.mainTitleTree3 == "" ? "displayNone" : ""
              }`}
            >
              {props.info.mainTitleTree3}
            </p>
            <div
              className={`research-theoretical-session-content research-session-content ${
                props.info.subTitleTree3 == "" &&
                (props.info.contentTree3 == "" ||
                  props.info.contentTree3 == "<p></p>\n")
                  ? "displayNone"
                  : ""
              }`}
            >
              <div className={"research-detail-session-row"}>
                <p
                  className={`research-detail-session-title ${
                    props.info.subTitleTree3 == "" ? "displayNone" : ""
                  }`}
                >
                  {props.info.subTitleTree3}
                </p>
                <div
                  className={`research-detail-session-text ${
                    props.info.contentTree3 == "" ||
                    props.info.contentTree3 == "<p></p>\n"
                      ? "displayNone"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: props.info.contentTree3,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className={`research-publication-session research-session ${
              props.info.mainTitleTree4 == "" &&
              props.info.subTitleTree4 == "" &&
              (props.info.contentTree4 == "" ||
                props.info.contentTree4 == "<p></p>\n")
                ? "displayNone"
                : ""
            }`}
            id="research-publication-session"
          >
            <p className="session-title">{props.info.mainTitleTree4}</p>
            <div
              className={`research-publication-session-content research-session-content ${
                props.info.subTitleTree4 == "" &&
                (props.info.contentTree4 == "" ||
                  props.info.contentTree4 == "<p></p>\n")
                  ? "displayNone"
                  : ""
              }`}
            >
              <div className={"research-detail-session-row"}>
                <p
                  className={`research-detail-session-title ${
                    props.info.subTitleTree4 == "" ? "displayNone" : ""
                  }`}
                >
                  {props.info.subTitleTree4}
                </p>
                <div
                  className={`research-detail-session-text ${
                    props.info.contentTree4 == "" ||
                    props.info.contentTree4 == "<p></p>\n"
                      ? "displayNone"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: props.info.contentTree4,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="researchSub-scroll-to-top">
            <img
              src={btnSrc}
              onMouseOver={onMouseOverButton}
              onMouseOut={onMouseOutButton}
              onTouchStart={onMouseOverButton}
              onTouchEnd={onMouseOutButton}
              onClick={() => handleOnClick("#")}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ResearchesSub;
// export {};
