import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import slugify from "slugify";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import Poppage from "../Poppage/Poppage";
import TrainingCard, {
  TrainingCardMeta,
  TrainingInfo,
} from "../TrainingCard/TrainingCard";
import TrainingResource, {
  TrainingMaterial,
} from "../Training_resource/Training_resource";
import Training_sub from "../Training_sub/Training_sub";
import API from "../helper/ApiHelper";
import { setChinese, setEnglish } from "../languageSlice";
import { RootState } from "../store";
import ArrowPrevHover from "./Group 2351.webp";
import ArrowNextHover from "./Group 2352.webp";
import "./Training.css";
import ArrowPrev from "./arrow_forward_24px-1.webp";
import ArrowNext from "./arrow_forward_24px.webp";
import entryButton from "./entry-button.webp";
import GreyLeft from "./grey_left.webp";
import GreyRight from "./grey_right.webp";
import trainingPopupMobile from "./training-popup-mobile.webp";
import trainingPopup from "./training-popup.webp";
import training1 from "./training1.webp";
import training2 from "./training2.webp";
import Loading from "../Loading/Loading";

interface mainPageData {
  pageTitle: string;
  pageTitleEng: string;
}

interface trainingData {
  sessionTitle: string;
  sessionTitleEng: string;
  title: string;
  titleEng: string;
  time: string;
  date: string;
  organization: string;
  organizationEng: string;
  venue: string;
  venueEng: string;
  activity: string;
  activityEng: string;
  description: string;
  descriptionEng: string;
  paragraph: string;
  paragraphEng: string;
  joinUrl: string;
}

const Training = ({ baseRoute }: { baseRoute: string }) => {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  const [backendData, setbackendData] = useState([] as any);
  const [loadComplete, setloadComplete] = useState(false);
  const [sliderSlots, setSliderSlot] = useState(2);
  const [loadingText, setLoadingText] = useState("Loading");

  let TotalRows = [0, 0];
  // Language Control
  const language = useSelector((state: RootState) => state.language.language);
  //const language = "zh_hant";
  // console.log("language: ", language);
  let languageLabel: number = 1;
  if (language === "zh_hant") {
    languageLabel = 0;
    //console.log("languageLabel: ", languageLabel);
  } else {
    languageLabel = 1;
    //console.log("languageLabel: ", languageLabel);
  }
  const [session, setSession] = useState() as any;
  const [numberOfCards1, setnumberOfCards1] = useState(0 as number);
  const [numberOfCards2, setnumberOfCards2] = useState(0 as number);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  const [trainingInfoArray, setTrainingInfoArray] = useState<
    [TrainingInfo[], TrainingInfo[]]
  >([[], []]);
  const [trainingContents1, setTrainingContents1] = useState<TrainingInfo[]>(
    []
  );

  const [trainingContents2, setTrainingContents2] = useState<TrainingInfo[]>(
    []
  );

  const [trainingMaterialArray, setTrainingMaterialArray] = useState<
    TrainingMaterial[]
  >([]);

  const [cardPositionArr, setCardPositionArr] = useState([] as any);
  const [maxCardHeight, setMaxCardHeight] = useState<number | null>(null);

  function getData() {
    const request = `{menuBar(where: $where) {
      id,
      title,
      parentContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2,where: $imagesWhere2){
          url
          priority
          category
          id
          title
          type
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }
        videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
          url
          priority
          category
          title
          id
          type
          isDeleted
        }
        audios (orderBy: $audiosOrderBy2, where: $audiosWhere2) {
          url
          priority
          category
          title
          id
          type
          isDeleted
        }
        pdf(orderBy: $pdfOrderBy2,where: $pdfWhere2){
          originalFileName
          position
          priority
          title
          url
          category
          id
        }
        sessionImage
      }
    }
  }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!, $audiosOrderBy2: [AudioDetailOrderByInput!]!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!,$audiosWhere2: AudioDetailWhereInput!,$videosWhere2: VideoDetailWhereInput!,$contentsWhere2: ContentDetailWhereInput!,$pdfOrderBy2: [PdfDetailOrderByInput!]!,$pdfWhere2: PdfDetailWhereInput!)${request}`,
      variables: {
        where: { title: "Training" },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        videosWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        audiosWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        pdfWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        pdfOrderBy2: [
          {
            priority: "asc",
          },
        ],
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        audiosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        videosOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };
    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(res.data.data.menuBar.parentContent);
        setSession(res.data.data.menuBar.parentContent);
        // temp filter out the new data first, suppose to add this into each card using category to match which card display position
        let positionArr = [] as any;
        res.data.data.menuBar.parentContent.forEach((e: any) => {
          for (let i = e.contents.length - 1; i >= 0; i--) {
            if (e.contents[i].position === "cardPosition") {
              positionArr.push(e.contents[i]);
              e.contents.splice(i, 1);
            }
          }
        });

        // Get image by ApiHelper
        res.data.data.menuBar.parentContent.forEach((ee: any) => {
          ee.images.forEach((e: any) => {
            e.url = `${API.getImage}${e.url}`;
          });
        });
        // Get pdf by ApiHelper
        res.data.data.menuBar.parentContent.forEach((ee: any) => {
          ee.pdf.forEach((e: any) => {
            e.url = `${API.getPDF}${e.url}`;
          });
        });

        setCardPositionArr(positionArr);
        setbackendData(res.data.data.menuBar.parentContent);
      })
      .catch((error) => console.error(error));
  }
  function processData() {
    // Check if getData is succeed and all cards data are ready (not undefinded)
    if (backendData[1]) {
      //console.log("backend", backendData);
      // Local variable that stores card data group by category and position
      const contents1 = backendData[1].contents.reduce((acc: any, cur: any) => {
        const { category, position } = cur; // Point to current item by identifing unique category and position
        const cardKey = category.split("@@")[0]; // Extract the part before "@@" symbols

        // Init the url field in contents
        if (
          cur.position == "title" &&
          cur.langLabel[0] &&
          cur.langLabel[0].lang === "en"
        ) {
          if (!acc[cardKey]) {
            acc[cardKey] = {};
          }
          acc[cardKey].link = cur.content;
          acc[cardKey].id = cur.id;
        }
        if (
          // (cur.langLabel[0] && cur.langLabel[0].lang === "en") ||
          (cur.langLabel[0] && cur.langLabel[0].lang === language) ||
          !cur.langLabel[0]
        ) {
          // Initalize accumulator if null
          if (!acc[cardKey]) {
            acc[cardKey] = {};
          }
          // Stores each current content according to their card and position, to the accumulator
          if (cur.position == "url") {
            if (!acc[cardKey]) {
              acc[cardKey] = {};
            }
            acc[cardKey].url = cur.content;
          }
          acc[cardKey][position] = cur.content;

          // console.log("cur.content:", cur.content);
        }
        return acc;
      }, {});
      delete contents1["Training"];
      // Map image to contents
      backendData[1].images.reduce((acc: any, cur: any) => {
        const { category } = cur;
        const cardKey = category.split("@@")[0];

        // acc already initalized and just put the url of the reduced images to acc
        if (acc[cardKey]) {
          // console.log("cur.url:", cur.url);
          if (cur.title == "Image") {
            acc[cardKey].image = cur.url;
          } else if (cur.title == "Poster / Image") {
            acc[cardKey].innerImage = cur.url;
          }
        }

        return acc;
      }, contents1);

      // Map pdf to contents
      backendData[1].pdf.reduce((acc: any, cur: any) => {
        const { category } = cur;
        const cardKey = category.split("@@")[0];

        // acc already initalized and just put the url of the reduced images to acc
        if (acc[cardKey]) {
          // console.log("cur.url:", cur.url);
          acc[cardKey].pdf = cur.url;
        }

        return acc;
      }, contents1);

      //console.log("contents1:", contents1);
      // Get the number of cards
      const cardKeys1 = Object.keys(contents1);
      setnumberOfCards1(cardKeys1.length); // Get the count of unique card keys
      //console.log("Number of cards1:", numberOfCards1);

      // Map the value in contents to infoArr with correct format that used to pass to other classes
      setTrainingContents1(
        Object.keys(contents1).map((key) => {
          let cardPosition = -1;

          if (cardPositionArr.length > 0) {
            cardPositionArr.forEach((e: any) => {
              if (e.category === key) {
                cardPosition = +e.content;
              }
            });
          }

          const value = contents1[key];
          //console.log("key: " + key);
          //console.log(value);

          return {
            id: key,
            info: {
              id: value.id,
              title: value.title,
              time: value.time,
              date: value.date,
              organization: value.organization,
              specialty: value.venue,
              event: value.activity,
              description: value.description,
              image: value.image,
              innerImage: value.innerImage,
              paragraph: value.paragraph,
              url: value.url,
              link: value.link,
              isShowMore: false,
              pdf: value.pdf,
              languageLabel: languageLabel,
              position: cardPosition,
            },
          };
        })
      );
    }

    if (backendData[2]) {
      // Local variable that stores card data group by category and position
      const contents2 = backendData[2].contents.reduce((acc: any, cur: any) => {
        const { category, position } = cur; // Point to current item by identifing unique category and position
        const cardKey = category.split("@@")[0]; // Extract the part before "@@" symbols
        // Init the url field in contents
        if (
          cur.position == "title" &&
          cur.langLabel[0] &&
          cur.langLabel[0].lang === "en"
        ) {
          if (!acc[cardKey]) {
            acc[cardKey] = {};
          }
          acc[cardKey].link = cur.content;
          acc[cardKey].id = cur.id;
        }

        if (
          // (cur.langLabel[0] && cur.langLabel[0].lang === "en") ||
          (cur.langLabel[0] && cur.langLabel[0].lang === language) ||
          !cur.langLabel[0]
        ) {
          // Initalize accumulator if null
          if (!acc[cardKey]) {
            acc[cardKey] = {};
          }
          // Stores each current content according to their card and position, to the accumulator

          acc[cardKey][position] = cur.content;
        }
        // console.log("cur.content:", cur.content);

        return acc;
      }, {});
      delete contents2["Training"];
      // Map image to contents
      backendData[2].images.reduce((acc: any, cur: any) => {
        const { category } = cur;
        const cardKey = category.split("@@")[0];

        // acc already initalized and just put the url of the reduced images to acc
        if (acc[cardKey]) {
          // console.log("cur.url:", cur.url);
          if (cur.title == "Image") {
            acc[cardKey].image = cur.url;
          } else if (cur.title == "Poster / Image") {
            acc[cardKey].innerImage = cur.url;
          }
        }

        return acc;
      }, contents2);

      // Map pdf to contents
      backendData[2].pdf.reduce((acc: any, cur: any) => {
        const { category } = cur;
        const cardKey = category.split("@@")[0];

        // acc already initalized and just put the url of the reduced images to acc
        if (acc[cardKey]) {
          // console.log("cur.url:", cur.url);
          acc[cardKey].pdf = cur.url;
        }

        return acc;
      }, contents2);

      //console.log("contents2:", contents2);
      // Get the number of cards
      const cardKeys2 = Object.keys(contents2);
      setnumberOfCards2(cardKeys2.length); // Get the count of unique card keys
      //console.log("Number of cards2:", numberOfCards2);

      // Map the value in contents to infoArr with correct format that used to pass to other classes
      setTrainingContents2(
        Object.keys(contents2).map((key) => {
          let cardPosition = -1;

          if (cardPositionArr.length > 0) {
            cardPositionArr.forEach((e: any) => {
              if (e.category === key) {
                cardPosition = +e.content;
              }
            });
          }

          const value = contents2[key];
          //console.log("key: " + key);
          //console.log(value);

          return {
            id: key,
            info: {
              id: value.id,
              title: value.title,
              time: value.time,
              date: value.date,
              organization: value.organization,
              specialty: value.venue,
              event: value.activity,
              description: value.description,
              image: value.image,
              innerImage: value.innerImage,
              paragraph: value.paragraph,
              url: value.url,
              link: value.link,
              isShowMore: false,
              pdf: value.pdf,
              languageLabel: languageLabel,
              position: cardPosition,
            },
          };
        })
      );
    }

    // Check if getData is succeed and all cards data are ready (not undefinded)
    if (backendData[3]) {
      // Local variable that stores card data group by category and position
      const contents3 = backendData[3].contents.reduce((acc: any, cur: any) => {
        const { category, position } = cur; // Point to current item by identifing unique category and position
        const cardKey = category.split("@@")[0]; // Extract the part before "@@" symbols
        let treeKey: string = category.split("_treeView_")[1]; // Extract the part after _treeView_
        //if (!treeKey) treeKey = "cardCover"; // If the field is not treeView, default is cardCover
        // console.log("treeKey: ", treeKey);

        if (
          cur.position == "title" &&
          cur.langLabel[0] &&
          cur.langLabel[0].lang === "en"
        ) {
          if (!acc[cardKey]) {
            acc[cardKey] = {};
          }
          acc[cardKey].link = cur.content;
          acc[cardKey].id = cur.id;
        }
        if (
          // (cur.langLabel[0] && cur.langLabel[0].lang === "en") ||
          (cur.langLabel[0] && cur.langLabel[0].lang === language) ||
          !cur.langLabel[0]
        ) {
          // Initalize accumulator if null
          if (!acc[cardKey]) {
            acc[cardKey] = {};
          }
          if (!acc[cardKey][treeKey]) {
            acc[cardKey][treeKey] = {};
          }

          // Stores each current content according to their card and position, to the accumulator
          acc[cardKey][treeKey][position] = cur.content;
          // console.log("cur.content:", cur.content);
        }
        return acc;
      }, {});

      // Map pdf to contents
      backendData[3].pdf.reduce((acc: any, cur: any) => {
        const { category } = cur;
        const cardKey = category.split("@@")[0];

        // acc already initalized and just put the url of the reduced images to acc
        if (acc[cardKey]) {
          // console.log("cur.url:", cur.url);
          acc[cardKey].pdf = cur.url;
        }

        return acc;
      }, contents3);

      // Map image to contents
      backendData[3].images.reduce((acc: any, cur: any) => {
        const { category } = cur;
        const cardKey = category.split("@@")[0];
        let treeKey: string = category.split("_treeView_")[1];

        // acc already initalized and just put the url of the reduced images to acc
        if (acc[cardKey][treeKey]) {
          acc[cardKey][treeKey].image = cur.url;
        }

        return acc;
      }, contents3);

      // Map videos to contents
      backendData[3].videos.reduce((acc: any, cur: any) => {
        const { category } = cur;
        const cardKey = category.split("@@")[0];
        let treeKey: string = category.split("_treeView_")[1];

        // acc already initalized and just put the url of the reduced images to acc
        if (acc[cardKey][treeKey]) {
          acc[cardKey][treeKey].video = cur.url;
        }

        return acc;
      }, contents3);

      // console.log("contents3:", contents3);
      delete contents3["Training"];
      // Get the number of cards
      const cardKeys = Object.keys(contents3);
      const numberOfCards = cardKeys.length; // Get the count of unique card keys
      // console.log("Number of cards:", numberOfCards);

      // Map the value in contents to infoArr with correct format that used to pass to other classes
      let result = Object.keys(contents3).map((key) => {
        let cardPosition = -1;

        if (cardPositionArr.length > 0) {
          cardPositionArr.forEach((e: any) => {
            if (e.category === key) {
              cardPosition = +e.content;
            }
          });
        }

        const value = contents3[key];

        let treeView: string[][] = [];
        let indexInner: number = 0;
        let indexOuter: number = 0;
        for (let i = 0; i < 4; i++) {
          treeView[i] = [];
        }

        // console.log("key: ", key);
        // console.log("value: ", value);

        // Iterate over each cards

        Object.entries(value).forEach(([id, item]) => {
          // console.log(`ID: ${id}`);
          // console.log("Data:", item);
          indexInner = 0;

          if (id != "cardCover" && id != "pdf" && id != "link" && id != "id") {
            // Iterate over the properties of the treeView
            Object.entries(item as Record<string, string>).forEach(
              ([key, data]) => {
                treeView[indexOuter][indexInner] = data || "";
                // console.log(`data: ${data}`);

                // Debug msg to determine index
                // console.log(`Property: ${key}`);
                // console.log(
                //   "treeView[indexOuter][indexInner]: ",
                //   treeView[indexOuter][indexInner]
                // );
                // console.log("indexOuter: ", indexOuter);
                // console.log("indexInner: ", indexInner);

                indexInner++;
              }
            );
            indexOuter++;
          }
          // console.log("treeview: ", treeView);
        });

        //  console.log("treeview: ", treeView);

        return {
          id: key,
          material: {
            id: value.id,
            title: treeView[0][0] || "",
            content11: treeView[0][1] || "",
            icaption1: treeView[0][2] || "",
            content12: treeView[0][3] || "",
            image1: treeView[0][4] || "",
            vcaption1: treeView[1][0] || "",
            content2: treeView[1][1] || "",
            video1: treeView[1][2] || "",
            icaption2: treeView[2][0] || "",
            content3: treeView[2][1] || "",
            image2: treeView[2][2] || "",
            vcaption2: treeView[3][0] || "",
            content4: treeView[3][1] || "",
            video2: treeView[3][2] || "",
            pdf: value.pdf,
            link: value.link,
            position: cardPosition,
          },
        };
      });
      result.sort((a, b) => {
        return a.material.position - b.material.position;
      });
      setTrainingMaterialArray(result);
      // console.log("trainingMaterialArray", trainingMaterialArray);
    }
  }

  function mountData() {
    trainingContents1.sort((a, b) => {
      return a.info.position - b.info.position;
    });
    trainingContents2.sort((a, b) => {
      return a.info.position - b.info.position;
    });

    setTrainingInfoArray([trainingContents1, trainingContents2]);
  }
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
  }, [currentUrl]);

  useEffect(() => {
    getData();
  }, [language]);

  useEffect(() => {
    processData();
  }, [backendData]);

  useEffect(() => {
    mountData();
    //console.log("trainingArray:", trainingInfoArray.length);
    if (trainingInfoArray[0].length > 0 || trainingInfoArray[1].length > 0) {
      setloadComplete(true);
      didLoad();
    }
    TotalRows = [
      Math.round(trainingInfoArray[0].length / sliderSlots),
      Math.round(trainingInfoArray[1].length / sliderSlots),
    ];
    //console.log("loadComplete:", loadComplete);
  }, [trainingContents1]);

  useEffect(() => {
    mountData();
    //console.log("trainingArray:", trainingInfoArray.length);
    if (trainingInfoArray[0].length > 0 || trainingInfoArray[1].length > 0) {
      setloadComplete(true);
      didLoad();
    }
    TotalRows = [
      Math.round(trainingInfoArray[0].length / sliderSlots),
      Math.round(trainingInfoArray[1].length / sliderSlots),
    ];
    //console.log("loadComplete:", loadComplete);
    // console.log("traningInfoArr:", trainingInfoArray);
  }, [trainingContents2]);

  function didLoad() {
    const hash = window.location.hash;
    const sectionId: string = "#training-resource-container";
    if (hash === sectionId) {
      tryGotoSection(sectionId, 50);
    }
  }

  function tryGotoSection(sectionId: string, interval: number) {
    const result: boolean = gotoSection(sectionId);
    if (!result) {
      setTimeout(() => {
        tryGotoSection(sectionId, interval);
      }, interval);
    }
  }

  function gotoSection(sectionId: string) {
    const target = document.querySelector(sectionId);
    target?.scrollIntoView({ behavior: "smooth" });
    return target !== null;
  }
  // Array to stores all cards data, group by card

  /* const trainingInfo = [
    {
      title: "為前線人員而設：接納與承諾治療深化輔導工作坊",
      time: "11:30-13:30",
      date: "17/06/2024",
      organization: "Y.K. Pao Foundation Centre for Nursing",
      specialty: "Chronic Illness Care",
      event: "Annual Professional Development Seminar",
      description:
        "We are thrilled to announce that our Y.K. Pao Foundation Centre for Nursing Excellence in Chronic Illness Care (CIC Centre) is organising the annual Professional Development Seminar on 24 June. So honoured to have a number of global and local experts in chronic illness care speaking as guests for us over this year’s theme, ‘Advancing Health Equity in Chronic Illness Care’!",
      image: "test.ipg",
    },
  ]; */

  function TrainingHome({
    propsarray,
    baseRoute,
  }: {
    propsarray: [TrainingInfo[], TrainingInfo[]];
    baseRoute: string;
  }) {
    const [scrollPositions, setScrollPositions] = useState([0, 0]);
    const [prevBtnSrc, setPrevBtnSrc] = useState([
      ArrowPrev,
      ArrowPrev,
      ArrowPrev,
    ]);
    const [backBtnSrc, setBackBtnSrc] = useState([
      ArrowNext,
      ArrowNext,
      ArrowNext,
    ]);

    const [isSectionOneCardUpdated, setIsSectionOneCardUpdated] =
      useState<boolean>(false);
    const [isSectionTwoCardUpdated, setIsSectionTwoCardUpdated] =
      useState<boolean>(false);

    const handlePrevClick = (index: number) => {
      const newScrollPositions = [...scrollPositions];
      newScrollPositions[index] = Math.max(
        newScrollPositions[index] - sliderSlots,
        0
      );
      setScrollPositions(newScrollPositions);
    };

    function onMouseOverPrevButton(e: any, index: number) {
      if (e.currentTarget?.disabled === true) return;
      const tmp = [...prevBtnSrc];
      tmp[index] = ArrowPrevHover;

      setPrevBtnSrc(tmp);
    }

    function onMouseOutPrevButton(e: any, index: number) {
      const tmp = [...prevBtnSrc];
      tmp[index] = ArrowPrev;
      setPrevBtnSrc(tmp);
    }

    const handleNextClick = (index: number) => {
      const newScrollPositions = [...scrollPositions];
      newScrollPositions[index] = newScrollPositions[index] + sliderSlots;
      setScrollPositions(newScrollPositions);
    };

    function onMouseOverBackButton(e: any, index: number) {
      if (e.currentTarget?.disabled === true) return;
      const tmp = [...backBtnSrc];
      tmp[index] = ArrowNextHover;
      setBackBtnSrc(tmp);
    }

    function onMouseOutBackButton(e: any, index: number) {
      const tmp = [...backBtnSrc];
      tmp[index] = ArrowNext;
      setBackBtnSrc(tmp);
    }
    const handlePopupClose = () => {
      if (role == "") {
        navigate(lanString + "/login");
      } else if (role == "participant") {
        setShowPopup(false);
      }
      // Remove the class from the body element to remove the CSS styles
      document.body?.classList.remove("popup-open");
    };

    function resolveMaterialEnter() {
      if (role !== "trainee") {
        setShowPopup(true);
        // Add a class to the body element to apply the CSS styles
        document.body?.classList.add("popup-open");
      }
    }

    // const [role, setRole] = React.useState("" as string);

    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
      setIsTouchDevice(
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.maxTouchPoints > 0
      );
    }, []);

    useEffect(() => {
      setIsSectionOneCardUpdated(true);
      const tid = setTimeout(() => {
        setIsSectionOneCardUpdated(false);
      }, 350);
      return () => {
        clearTimeout(tid);
      };
    }, [scrollPositions[0]]);
    useEffect(() => {
      setSectionOneCardMeta({
        height: maxCardHeight,
        isCardDataUpdated: isSectionOneCardUpdated,
      });
    }, [isSectionOneCardUpdated]);

    useEffect(() => {
      setIsSectionTwoCardUpdated(true);
      const tid = setTimeout(() => {
        setIsSectionTwoCardUpdated(false);
      }, 350);
      return () => {
        clearTimeout(tid);
      };
    }, [scrollPositions[1]]);
    useEffect(() => {
      setSectionTwoCardMeta({
        height: maxCardHeight,
        isCardDataUpdated: isSectionTwoCardUpdated,
      });
    }, [isSectionTwoCardUpdated]);

    const [lanString, setLanString] = useState([] as any);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState(450);
    const [popupImageSrc, setPopupImageSrc] = useState("");
    const role = useSelector((state: RootState) => state.role.role);

    const [sectionOneCardMeta, setSectionOneCardMeta] =
      useState<TrainingCardMeta>({
        height: maxCardHeight,
        isCardDataUpdated: isSectionOneCardUpdated,
      });
    const [sectionTwoCardMeta, setSectionTwoCardMeta] =
      useState<TrainingCardMeta>({
        height: maxCardHeight,
        isCardDataUpdated: isSectionTwoCardUpdated,
      });

    // React.useEffect(() => {
    //   if (Cookies.get("role")) {
    //     localStorage.setItem("logging", "true");
    //     if (!role.length) {
    //       setRole(Cookies.get("role") as string);
    //       console.log("role", role);
    //     }
    //   } else {
    //     localStorage.removeItem("logging");
    //     if (role.length) {
    //       setRole("");
    //     }
    //   }
    // });
    useEffect(() => {
      function handleResize() {
        setScreenWidth(window.innerWidth);
        if (window.innerWidth <= 769) {
          setSliderSlot(1);
          TotalRows = [
            Math.round(trainingInfoArray[0].length / sliderSlots),
            Math.round(trainingInfoArray[1].length / sliderSlots),
          ];
        } else {
          setSliderSlot(2);
          TotalRows = [
            Math.round(trainingInfoArray[0].length / sliderSlots),
            Math.round(trainingInfoArray[1].length / sliderSlots),
          ];
        }

        if (screenWidth >= 769) {
          setPopupImageSrc(trainingPopup);
        } else {
          setPopupImageSrc(trainingPopupMobile);
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      if (language === "zh_hant") {
        setLoadingText("加載中");
        document.title = "專業培訓 - ACTuwise"; // Update the document title on mount
        setLanString("/ch");
      } else {
        setLoadingText("Loading");
        document.title = "Professional Training - ACTuwise"; // Update the document title on mount
        setLanString("/en");
      }
    }, [language]);
    //Loading UI Control
    const [isLoading, setIsLoading] = useState(true);
    let loading = true;
    useEffect(() => {
      setIsLoading(true);
      if (
        (trainingInfoArray[0].length ||
        trainingInfoArray[1].length) &&
        trainingMaterialArray.length
      ) {
        setIsLoading(false);
      }
    }, [trainingInfoArray, trainingMaterialArray]);

    useEffect(() => {
      if (isLoading) return;

      let maxHeight: number = 0;
      let eles = document.getElementsByClassName("training-basic-info");
      for (let i = 0; i < eles.length; i++) {
        const ele: HTMLElement = eles[i] as HTMLElement;
        maxHeight = Math.max(maxHeight, ele.clientHeight);
      }
      if (maxHeight === 0) {
        setMaxCardHeight(null);
      } else {
        setMaxCardHeight(maxHeight);
      }
    }, [isLoading]);

    // avoid change language error
    // if (languageLabel) {
    //   if (language === "zh_hant") {
    //     loading = false;
    //   }
    // } else {
    //   if (language === "en") {
    //     loading = false;
    //   }
    // }

    function grepTrainingCardDataSource(index: number): Array<TrainingInfo> {
      return trainingInfoArray[index].slice(
        scrollPositions[index],
        scrollPositions[index] + sliderSlots
      )
    }

    /**
     * Function to generate the training card UI.
     * @param index The section: number, [0...1], 0 means `Upcoming Trainings and Events`, 1 means `Details of Past Events`.
     * @returns 
     */
    function generateTrainingCards(index: number) {
      // Grep the data source
      const dataSource = grepTrainingCardDataSource(index)
      // Empty case
      if (dataSource.length === 0) {
        const message: string = language === 'zh_hant' ? '暫時沒有最新活動' : 'There are no latest activities available at the moment.'
        return <p>{message}</p>
      }
      // Normal case
      return dataSource.map((Info, id) => (
        <TrainingCard
          props={Info}
          cardMeta={
            index === 0
              ? sectionOneCardMeta
              : sectionTwoCardMeta
          }
          baseRoute={baseRoute}
          key={id}
        />
      ))
    }

    if (isLoading) {
      return <Loading loadingText={loadingText} />;
    }

    return (
      <div className="holder">
        <Header2 url="/professional-training" />
        {backendData[0] && (
          <h1
            id="training-title"
            dangerouslySetInnerHTML={{
              __html: backendData[0].contents[0 + languageLabel].content,
            }}
          ></h1>
        )}

        <div id="training-content-container">
          {[0, 1].map((index) => {
            return (
              <React.Fragment key={index}>
                <div className="training-cardContainer" key={index}>
                  {index === 0 && backendData[1] && (
                    <h3
                      className="new-event-title"
                      dangerouslySetInnerHTML={{
                        __html:
                          backendData[1].contents[0 + languageLabel].content,
                      }}
                    ></h3>
                  )}
                  {index === 1 && backendData[2] && (
                    <h3
                      className="old-event-title"
                      dangerouslySetInnerHTML={{
                        __html:
                          backendData[2].contents[0 + languageLabel].content,
                      }}
                    ></h3>
                  )}
                  {trainingInfoArray[index].length > 0 && (
                    <div className="arrow-container">
                      {isTouchDevice ? (
                        <button
                          className={`card-button`}
                          disabled={scrollPositions[index] === 0}
                          onClick={() => handlePrevClick(index)}
                          onTouchStart={(e) => onMouseOverPrevButton(e, index)}
                          onTouchEnd={(e) => onMouseOutPrevButton(e, index)}
                        >
                          {scrollPositions[index] === 0 ? (
                            <img src={GreyLeft} alt="Previous" />
                          ) : (
                            <img src={prevBtnSrc[index]} alt="Previous" />
                          )}
                        </button>
                      ) : (
                        <button
                          className={`card-button`}
                          disabled={scrollPositions[index] === 0}
                          onClick={() => handlePrevClick(index)}
                          onMouseOver={(e) => onMouseOverPrevButton(e, index)}
                          onMouseOut={(e) => onMouseOutPrevButton(e, index)}
                          onTouchStart={(e) => onMouseOverPrevButton(e, index)}
                          onTouchEnd={(e) => onMouseOutPrevButton(e, index)}
                        >
                          {scrollPositions[index] === 0 ? (
                            <img src={GreyLeft} alt="Previous" />
                          ) : (
                            <img src={prevBtnSrc[index]} alt="Previous" />
                          )}
                        </button>
                      )}
                      <div id="slide-position-display">
                        {scrollPositions[index] / sliderSlots + 1}/
                        {TotalRows[index]}
                      </div>
                      {isTouchDevice ? (
                        <button
                          className={`card-button`}
                          disabled={
                            scrollPositions[index] / sliderSlots + 1 >=
                            TotalRows[index] || TotalRows[index] < 2
                          }
                          onClick={() => handleNextClick(index)}
                          onTouchStart={(e) => onMouseOverBackButton(e, index)}
                          onTouchEnd={(e) => onMouseOutBackButton(e, index)}
                        >
                          {scrollPositions[index] / sliderSlots + 1 >=
                            TotalRows[index] || TotalRows[index] < 2 ? (
                            <img src={GreyRight} alt="Next" />
                          ) : (
                            <img src={backBtnSrc[index]} alt="Next" />
                          )}
                        </button>
                      ) : (
                        <button
                          className={`card-button`}
                          disabled={
                            scrollPositions[index] / sliderSlots + 1 >=
                            TotalRows[index] || TotalRows[index] < 2
                          }
                          onClick={() => handleNextClick(index)}
                          onMouseOver={(e) => onMouseOverBackButton(e, index)}
                          onMouseOut={(e) => onMouseOutBackButton(e, index)}
                          onTouchStart={(e) => onMouseOverBackButton(e, index)}
                          onTouchEnd={(e) => onMouseOutBackButton(e, index)}
                        >
                          {scrollPositions[index] / sliderSlots + 1 >=
                            TotalRows[index] || TotalRows[index] < 2 ? (
                            <img src={GreyRight} alt="Previous" />
                          ) : (
                            <img src={backBtnSrc[index]} alt="Previous" />
                          )}
                        </button>
                      )}
                    </div>
                  )}
                  <div className="training-card-container">
                    {generateTrainingCards(index)}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          <div id="training-resource-container">
            {backendData[3] && (
              <h3
                className="training-resource-title"
                dangerouslySetInnerHTML={{
                  __html: backendData[3].contents[0 + languageLabel].content,
                }}
              ></h3>
            )}
            <div className="training-resource-image-container">
              <img
                id="training-resource-img"
                src={window.innerWidth < 993 ? training2 : training1}
                alt=""
              ></img>
              <div id="training-resource-enter-section">
                <div className="material-enter-container">
                  <div className="material-center-container">
                    {backendData[3] && (
                      <h3
                        className="training-enter-title"
                        dangerouslySetInnerHTML={{
                          __html:
                            backendData[3].contents[2 + languageLabel].content,
                        }}
                      ></h3>
                    )}
                    <div onClick={resolveMaterialEnter}>
                      {role === "trainee" ? (
                        <Link
                          to={
                            "training-materials/" +
                            slugify(trainingMaterialArray[0]?.material?.link, {
                              lower: true,
                            }) +
                            "-" +
                            trainingMaterialArray[0]?.material?.id
                          }
                        >
                          <div className="training-material-enter-container">
                            <img
                              id="training-enter-img"
                              src={entryButton}
                              alt=""
                            ></img>
                            {languageLabel === 0 ? (
                              <h3 className="training-enter-text">進入</h3>
                            ) : (
                              <h3 className="training-enter-text">Enter</h3>
                            )}
                          </div>
                        </Link>
                      ) : (
                        <>
                          <div className="training-material-enter-container">
                            <img
                              id="training-enter-img"
                              src={entryButton}
                              alt=""
                            ></img>
                            {languageLabel === 0 ? (
                              <h3 className="training-enter-text">進入</h3>
                            ) : (
                              <h3 className="training-enter-text">Enter</h3>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        {showPopup ? (
          <>
            <div
              className="overlay"
              style={{ display: showPopup ? "block" : "none" }}
            ></div>
            <div
              id="popup"
              style={{
                display: showPopup ? "flex" : "none",
                top: popupPosition,
              }}
            >
              <img id="training-popup-img" src={popupImageSrc} alt=""></img>
              {language == "en" ? (
                role != "" ? (
                  <p id="popup-text">
                    Your account don't have access right to this page
                  </p>
                ) : (
                  <p id="popup-text">Please log in to access this page</p>
                )
              ) : (
                <p id="popup-text">請登入以訪問此頁面</p>
              )}
              <button id="popup-button" onClick={handlePopupClose}>
                OK
              </button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
  useEffect(() => {
    if (language === "zh_hant") {
      setLoadingText("加載中");
    } else {
      setLoadingText("Loading");
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  const role = useSelector((state: RootState) => state.role.role);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    console.log(`[Training] info array 0: ${trainingInfoArray[0].length}, 1: ${trainingInfoArray[1].length}`)
    console.log(`[Training] material array: ${trainingMaterialArray.length}`)
    if (
      (trainingInfoArray[0].length ||
      trainingInfoArray[1].length) &&
      trainingMaterialArray.length
    ) {
      console.log(`yeah`)
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [trainingInfoArray, trainingMaterialArray]);
  // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  }
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <TrainingHome
              propsarray={trainingInfoArray}
              baseRoute={baseRoute}
            />
          }
        />
        {trainingInfoArray[0].map((traininginfo, id) => (
          <Route
            path={
              slugify(traininginfo.info.link, { lower: true }) +
              "-" +
              traininginfo.info.id
            }
            element={
              <Training_sub
                props={traininginfo}
                baseRoute={baseRoute}
                key={id}
              />
            }
            key={id}
          />
        ))}
        {trainingInfoArray[1].map((traininginfo, id) => (
          <Route
            path={
              slugify(traininginfo.info.link, { lower: true }) +
              "-" +
              traininginfo.info.id
            }
            element={
              <Training_sub
                props={traininginfo}
                baseRoute={baseRoute}
                key={id}
              />
            }
            key={id}
          />
        ))}
        {role === "trainee"
          ? trainingMaterialArray.map((TrainingMaterial, id) => (
            <Route
              path={
                "training-materials/" +
                slugify(TrainingMaterial.material.link, { lower: true }) +
                "-" +
                TrainingMaterial.material.id
              }
              element={
                <TrainingResource
                  props={trainingMaterialArray}
                  baseRoute={baseRoute}
                  cardIndex={id}
                  key={id}
                />
              }
              key={id}
            />
          ))
          : trainingMaterialArray.map((TrainingMaterial, id) => (
            <Route
              path={
                "training-materials/" +
                slugify(TrainingMaterial.material.link, { lower: true }) +
                "-" +
                TrainingMaterial.material.id
              }
              element={<Poppage />}
              key={id}
            />
          ))}

        <Route
          path="*"
          element={<h3 style={{ textAlign: "center" }}>404 Not Found</h3>}
        />
      </Routes>
    </div>
  );
};

export default Training;
