import React, { useEffect, useState } from "react";
import "./SectionBar.css";
import { useSelector } from "react-redux";
import { RootState } from "../store";

function SectionBar(props: { sections: { name: string; url: string }[] }) {
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [sectionY, setSectionY] = useState([0, 0, 0]);
  const language = useSelector((state: RootState) => state.language.language);  
  function handleOnClick(url: string) {
    if (currentUrl === url) {
      return;
    }
    setCurrentUrl(url);
    window.location.href = url;
  }

  function getSectionY() {
    let posY: number[] = [0, 0, 0];
    const topDiv = document.getElementById('what-is-top-section');
    const middleDiv = document.getElementById('what-is-middle-section');
    const bottomDiv = document.getElementById('what-is-bottom-section');
    posY[0] = topDiv ? topDiv.offsetTop : 0;
    posY[1] = middleDiv ? middleDiv.offsetTop : 0;
    posY[2] = bottomDiv ? bottomDiv.offsetTop : 0;
    setSectionY(posY);
    return (posY);
  }

  useEffect(() => {
    getSectionY()
  }, [])
  const [lanString, setLanString] = useState([] as any);
  const isPhone = window.matchMedia("(max-width: 768px)").matches;
  useEffect(() => {
    if (language === "zh_hant") {
      setLanString("/ch");
    } else {
      setLanString("/en");
    }
  }, [language]);
  useEffect(() => {
    if (true) {
      const sectionBar = document.querySelector(".what-is-section-bar") as HTMLElement;
      let prevScrollPos = window.pageYOffset;

      window.addEventListener("scroll", () => {
        const currentScrollPos = window.pageYOffset;
        let posY: number[] = [];
        if (!sectionY[0]) {
          posY = getSectionY();
        }

        if (currentScrollPos >= posY[2] - 1) {
          setCurrentUrl(lanString + "/what-is-act#what-is-bottom-section");
        } else if (currentScrollPos >= posY[1] - 1) {
          setCurrentUrl(lanString + "/what-is-act#what-is-middle-section");
        } else if (currentScrollPos >= posY[0] - 1) {
          setCurrentUrl(lanString + "/what-is-act#what-is-top-section");
        } else {
          setCurrentUrl(lanString + "/what-is-act");
        }

        if (currentScrollPos > window.innerHeight * 0.4) {
          if (prevScrollPos > currentScrollPos) {
            sectionBar?.classList.add("scroll-up");
          } else {
            sectionBar?.classList.remove("scroll-up");
          }
        } else {
          sectionBar?.classList.remove("scroll-up");
        }

        prevScrollPos = currentScrollPos;
      });
    }
  }, [isPhone]);

  return (
    <div className="what-is-section-bar">
      <div className="what-is-section-bar-container">
        {props.sections.map((section) => {
          return currentUrl === section.url ? (
            <div
              className="what-is-section what-is-section-current"
              key={section.name}
              onClick={() => handleOnClick(lanString + section.url)}
            >
              {section.name}
            </div>
          ) : (
            <div
              className="what-is-section"
              key={section.name}
              onClick={() => handleOnClick(lanString + section.url)}
            >
              {section.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SectionBar;
