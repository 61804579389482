import React, { useEffect, useState } from "react";
import "./Module.css";
import Footer from "../Footer/Footer";
import SectionBarElement from "./ModuleSectionBar";
import test from "./test2.webp";
import reading from "./module2.webp";
import openBtn from "./openBtn.webp";
import closeBtn from "./closeBtn.webp";
import playButton from "./Frame_264.webp";
// import demoVideo from "./SampleVideo.mp4";
// import demoVideo2 from "./SampleVideo2.mp4";
// import demoVideo3 from "./SampleVideo3.mp4";
// import demoVideo4 from "./SampleVideo4.mp4";
import nextButton from "./nextBtn.webp";
import prevButton from "./prevBtn.webp";
import card1 from "./page_1-3.webp";
import card2 from "./page_2-3.webp";
import card3 from "./page_3-3.webp";
import card4 from "./page_4-3.webp";
import progress1y from "./progress1_2.webp";
import progress2y from "./progress2_2.webp";
import progress3y from "./progress3_2.webp";
import progress4y from "./progress4_2.webp";
import progress5y from "./progress5_2.webp";
import progress6y from "./progress6_2.webp";
import progress7y from "./progress7_2.webp";
import progress8y from "./progress8_2.webp";
import progress9y from "./progress9_2.webp";
import progress10y from "./progress10_2.webp";
import progress2n from "./progress2_1.webp";
import progress3n from "./progress3_1.webp";
import progress4n from "./progress4_1.webp";
import progress5n from "./progress5_1.webp";
import progress6n from "./progress6_1.webp";
import progress7n from "./progress7_1.webp";
import progress8n from "./progress8_1.webp";
import progress9n from "./progress9_1.webp";
import progress10n from "./progress10_1.webp";
// import no1 from "./no1.webp";
import Header2 from "../Header2/Header2";
// import story1 from "./1-S1.4.jpg";
// import story2 from "./1-S2.4.jpg";
// import story3 from "./1-S3.6.jpg";
// import story4 from "./1-S4.4.jpg";
// import story5 from "./1-S5.3.jpg";
// import story6 from "./1-S6.3.jpg";
// import story7 from "./1-S7.1.jpg";
// import story8 from "./1-S8.4.jpg";
import tempImage from "./tempImage.webp";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
// import { element } from "prop-types";
// import { Category } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

interface ReadingCard {
  title: string;
  category: string;
  image: string;
  color: string;
  sessions: { subtitle: string; details: string }[];
  position: number;
}

function ModuleThree() {
  const [backendData, setBackendData] = useState([] as any);
  const [readingData, setReadingData] = useState([] as any);
  const [storyData, setStoryData] = useState([] as any);
  const [storyImage, setStoryImage] = useState([] as any);
  const [exerciseData, setExerciseData] = useState([] as any);
  const [exerciseImageData, setExerciseImageData] = useState([] as any);
  const [exerciseDataFinal, setExerciseDataFinal] = useState([] as any);
  const [exerciseAudioData, setExerciseAudioData] = useState([] as any);
  const [exerciseNewImageData, setExerciseNewImageData] = useState([] as any);
  const [title, setTitle] = useState([] as any);
  const [isExercise, setIsExercise] = useState<boolean>(false);
  const [cardPositionArr, setCardPositionArr] = useState([] as any);
  //   const [readingLength,setReadingLength]=useState([] as any);
  //   const [sessionLength,setSessionLength]=useState([] as any);
  const [isStoryCardUpdated, setIsStoryCardUpdated] = useState<boolean>(false);

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const [loadingText, setLoadingText] = useState("Loading");
  async function getData() {
    const request = `{childMenu(where: $where) {
      id,
      title,
      childContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2){
          url
          priority
          category
          id
          title
          type
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }
        videos(orderBy: $videosOrderBy2) {
          url
          priority
          category
          title
          id
          type
          isDeleted
        }
        audios (orderBy: $audiosOrderBy2) {
          url
          priority
          category
          title
          id
          type
          isDeleted
        }
        pdf(orderBy: $pdfOrderBy2){
          originalFileName
          position
          priority
          title
          url
          category
          id
          isDeleted
        }
        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!, $audiosOrderBy2: [AudioDetailOrderByInput!]!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$pdfOrderBy2: [PdfDetailOrderByInput!]!) ${request}`,
      variables: {
        where: { title: "Module2" },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        audiosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        videosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        pdfOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.childMenu.childContent: ",
        //   res.data.data.childMenu.childContent
        // );
        res.data.data.childMenu.childContent.forEach((ee: any) => {
          ee.images.forEach((e: any) => {
            e.url = `${API.getImage}${e.url}`;
          });
        });

        res.data.data.childMenu.childContent.forEach((ee: any) => {
          ee.audios.forEach((e: any) => {
            e.url = `${API.getAudio}${e.url}`;
          });
        });

        // res.data.data.childMenu.childContent.forEach((ee: any) => {
        //   ee.videos.forEach((e: any) => {
        //     e.url = `${API.getVideo}${e.url}`;
        //   });
        // });

        // res.data.data.childMenu.childContent[2]?.contents?.forEach((element:any)=>{
        //   if(element.content!==""){
        //     setIsExercise(true)
        //   }
        // });

        let storyArr = [] as any;
        let storyImageArr = [] as any;
        let exerciseArr = [] as any;
        let exerciseVideoArr = [] as any;
        let exerciseImageArr = [] as any;
        let exerciseAudioArr = [] as any;
        let exerciseArrFinal = [] as any;
        let positionArr = [] as any;
        res.data.data.childMenu.childContent.forEach((e: any) => {
          for (let i = e.contents.length - 1; i >= 0; i--) {
            if (e.contents[i].position === "cardPosition") {
              positionArr.push(e.contents[i]);
              e.contents.splice(i, 1);
            }
          }
        });
        res.data.data.childMenu.childContent[1].contents.forEach(
          (e: any, index: number) => {
            if (!e.isDeleted && e.position != "cardPosition") {
              storyArr.push(e);
            }
          }
        );
        res.data.data.childMenu.childContent[1].images.forEach(
          (e: any, index: number) => {
            if (!e.isDeleted) {
              storyImageArr.push(e);
            }
          }
        );

        res.data.data.childMenu.childContent[2].contents.forEach(
          (e: any, index: number) => {
            if (!e.isDeleted) {
              exerciseArr.push(e);
            }
          }
        );

        exerciseArr?.forEach((element: any) => {
          if (element.content !== "") {
            setIsExercise(true);
          }
        });

        res.data.data.childMenu.childContent[2].videos.forEach(
          (e: any, index: number) => {
            if (!e.isDeleted) {
              exerciseImageArr.push(e);
            }
          }
        );

        res.data.data.childMenu.childContent[2].images.forEach(
          (e: any, index: number) => {
            if (!e.isDeleted) {
              exerciseVideoArr.push(e);
            }
          }
        );

        res.data.data.childMenu.childContent[2].audios.forEach(
          (e: any, index: number) => {
            if (!e.isDeleted) {
              exerciseAudioArr.push(e);
            }
          }
        );

        const splitObjects = {} as any;
        exerciseArr.forEach((item: any) => {
          const keyValue = item["category"];
          if (!splitObjects[keyValue]) {
            splitObjects[keyValue] = [];
          }
          splitObjects[keyValue].push(item);
        });

        exerciseArr = Object.values(splitObjects);
        exerciseArrFinal = exerciseArr.slice(1);

        let totalCardArr = [] as any;
        let totalCard = [] as any;
        // Filtering reading material backend data
        res.data.data.childMenu.childContent[0].images.forEach((e: any) => {
          totalCard.push(e);
        });

        res.data.data.childMenu.childContent[0].contents.forEach(
          (e: any, index: number) => {
            if (!e.isDeleted) {
              totalCard.push(e);
            }
          }
        );

        totalCardArr = totalCard.reduce((acc: any, cur: any) => {
          if (!acc[cur.category]) {
            acc[cur.category] = 1;
          } else {
            acc[cur.category]++;
          }
          return acc;
        }, {});

        // console.log(totalCard);
        // console.log(totalCardArr);
        exerciseArrFinal.sort((a: any, b: any) => {
          return +a[a.length - 1].content - +b[b.length - 1].content;
        });
        // console.log("storyArr:",storyArr);
        // console.log("storyImageArr:",storyImageArr);
        // console.log("exerciseArr:",exerciseArr);
        // console.log("exerciseImageArr:",exerciseImageArr);
        // console.log("exerciseArrFinal:",exerciseArrFinal);
        // console.log(exerciseAudioArr)
        // console.log(exerciseVideoArr)
        storyArr.forEach((e: any) => {
          positionArr.forEach((position: any) => {
            if (e.category === position.category) {
              e["cardPosition"] = +position.content;
            }
          });
        });
        storyImageArr.forEach((e: any) => {
          positionArr.forEach((position: any) => {
            if (e.category === position.category) {
              e["cardPosition"] = +position.content;
            }
          });
        });
        storyArr.sort((a: any, b: any) => +a.cardPosition - +b.cardPosition);
        storyImageArr.sort(
          (a: any, b: any) => +a.cardPosition - +b.cardPosition
        );
        setCardPositionArr(positionArr);
        setStoryData(storyArr);
        setStoryImage(storyImageArr);
        setExerciseData(exerciseArr);
        setExerciseImageData(exerciseImageArr);
        setExerciseDataFinal(exerciseArrFinal);
        setReadingData(totalCard);
        setExerciseAudioData(exerciseAudioArr);
        setExerciseNewImageData(exerciseVideoArr);

        setTitle(res.data.data.childMenu.childContent[3].contents);
        setBackendData(res.data.data.childMenu.childContent);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }
  const [selectReading, setSelectReading] = useState<undefined | number>(
    undefined
  );
  const [isSelectReadingSessionsOpen, setIsSelectReadingSessionsOpen] =
    useState<undefined | Boolean[]>(undefined);

  const data2 = readingData;
  const cardMap = new Map<string, ReadingCard>();
  let tempSubtitle: string = "";
  let indexColor: number = 0;
  const colors = [
    "#E2C067",
    "#A75438",
    "#4C86A8",
    "#C2DAD7",
    "#DA8439",
    "#DC5E3A",
    "#839791",
  ];

  // Group items by category
  data2.forEach((item: any, id: any) => {
    if (indexColor > colors.length) indexColor = 0;
    const { category, content, isDeleted, position, type, url } = item;
    // console.log("item:", item);
    if (category !== "Module3") {
      // Extract the card identifier from the category
      const cardId = category.split("@@")[0];
      // console.log("cardId:", cardId);

      // Check if the card exists in the map, if not create a new card object
      if (!cardMap.has(cardId)) {
        const card: ReadingCard = {
          title: "", // Set the appropriate default value
          image: "", // Set the appropriate default value
          color: "", // Set the appropriate default value
          category: "",
          sessions: [],
          position: 0,
        };
        cardMap.set(cardId, card);
      }

      // Retrieve the card from the map
      const card = cardMap.get(cardId);

      cardPositionArr.forEach((e: any) => {
        if (card && e.category === card?.category) {
          card.position = +e.content;
        }
      });

      if (card && !isDeleted) {
        // Update the card properties if necessary
        if (category) card.category = category;
        if (url) card.image = url;
        if (colors[indexColor]) card.color = colors[indexColor++]; // Assign color from the list
        if (position && position === "title") card.title = content;
        // Add the session to the card
        if (
          position &&
          position === "content" &&
          tempSubtitle !== "" &&
          content
        )
          card.sessions.push({ subtitle: tempSubtitle, details: content });
        else if (position && position === "subTitle" && content)
          tempSubtitle = content;
        else tempSubtitle = "";
      }
    }
  });

  // Extract the card objects from the map
  const cardContents: ReadingCard[] = Array.from(cardMap.values());
  cardContents.sort((a, b) => a.position - b.position);
  const language = useSelector((state: RootState) => state.language.language);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();

  let sectionElement = [] as any;
  if (cardContents.length) {
    sectionElement.push({
      name: "閱讀資源",
      url: "#module-title",
    });
  }
  if (storyData.length) {
    sectionElement.push({
      name: "情景研習",
      url: "#situations-session",
    });
  }
  if (isExercise) {
    sectionElement.push({
      name: "課後練習",
      url: "#exercises-session",
    });
  }
  // console.log(sectionElement)

  useEffect(() => {
    document.title = "Module 2 - ACT";
    getData(); // Update the document title on mount
  }, []);

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [selectExercise, setSelectExercise] = useState<undefined | number>(
    undefined
  );
  const [currentStoryBoard, setCurrentStoryBoard] = useState<number>(0);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    setIsTouchDevice(
      "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.maxTouchPoints > 0
    );
  }, []);
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
    } else {
      setLoadingText("Loading");
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (
      // readingData.length &&
      // cardContents.length &&
      // storyData.length &&
      // exerciseData[0].length &&
      // exerciseDataFinal.length &&
      backendData.length
    ) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [
    // readingData,
    backendData,
    // cardContents,
    // storyData,
    // exerciseData,
    // exerciseDataFinal,
  ]);

  useEffect(() => {
    setIsStoryCardUpdated(true);
    const tid = setTimeout(() => {
      setIsStoryCardUpdated(false);
    }, 350);
    return () => {
      clearTimeout(tid);
    };
  }, [currentStoryBoard]);

  // avoid change language error
  /* if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }

  return (
    <div className="wrapper">
      <Header2 url="/guided-act-modules-for-parents/module2" />
      <div className="content-section">
        <SectionBarElement sections={sectionElement} />
        <div className="module-container">
          <div id="middleSection" className="module-content">
            {title.map((e: any, index: number) => {
              return (
                <div>
                  {e.position === "title" &&
                    e.langLabel[0].lang === "zh_hant" && (
                      <h1
                        key={index}
                        className="module-title"
                        id="module-title"
                        dangerouslySetInnerHTML={{ __html: e?.content }}
                      ></h1>
                    )}
                  {/* {e.position==="title"&&language==='en'&&e.langLabel[0].lang===language&&(
                  <h1 key={index} className="module-title" id="module-title" dangerouslySetInnerHTML={{ __html: e?.content }}></h1>
                  )} */}
                </div>
              );
            })}
            <div
              className={"readings-session ModuleTwo-readings"}
              id="readings-session"
            >
              <p className="session-title">閱讀資源</p>
              {readingData.map((e: any, index: number) => {
                return (
                  <div>
                    {e.position === "description" && (
                      <p
                        className="session-title-paragraph"
                        dangerouslySetInnerHTML={{ __html: e?.content }}
                      ></p>
                    )}
                  </div>
                );
              })}
              {readingData.map((e: any, index: number) => {})}
              {selectReading !== undefined &&
                Number.isInteger(selectReading) && (
                  <div
                    className="expended-readings-container"
                    style={{ background: "rgba(232,200,106,0.5)" }}
                  >
                    {/* <button
                      onClick={() => {
                        console.log("API.getImage:", API.getImage);
                        console.log(
                          "cardContents[selectReading].image:",
                          cardContents[selectReading].image
                        );
                      }}
                    >
                      Hello
                    </button> */}
                    <img
                      className="expended-readings-img"
                      src={
                        API.getImage === cardContents[selectReading].image
                          ? tempImage
                          : cardContents[selectReading].image
                      }
                      alt="PLEASE ADD IMAGE IN ADMINPORTAL"
                    />
                    <p className="expended-reading-title">
                      {cardContents[selectReading].title}
                    </p>
                    {cardContents[selectReading].sessions.map(
                      (session, sessionIndex) => (
                        <>
                          <div
                            key={sessionIndex}
                            style={{
                              background: "#F5F5DB",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              padding: "0.2rem 1rem",
                              margin: "1rem 0",
                              marginBottom: "0.5rem",
                              borderRadius: "clamp(1rem, 5%, 1.1rem) / 45%",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsSelectReadingSessionsOpen((prev) => {
                                if (!prev) return;
                                const newArray = [...prev];
                                newArray[sessionIndex] =
                                  !newArray[sessionIndex];
                                return newArray;
                              });
                            }}
                          >
                            <div
                              style={{
                                flexGrow: 1,
                                marginRight: "1rem",
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <img
                                src={reading}
                                style={{
                                  height: "clamp(20px, 2vw, 25px)",
                                  margin: "0.2rem 0",
                                }}
                              />
                              <span
                                style={{
                                  margin: "auto auto auto 0.5rem",
                                  lineHeight: "1.6",
                                  fontSize: "1rem",
                                  fontWeight: "500",
                                }}
                              >
                                {session.subtitle}
                              </span>
                            </div>
                            <img
                              src={
                                isSelectReadingSessionsOpen?.[sessionIndex]
                                  ? closeBtn
                                  : openBtn
                              }
                              style={{
                                height: "clamp(20px, 2vw, 25px)",
                                margin: "0.2rem 0",
                              }}
                            />
                          </div>
                          {isSelectReadingSessionsOpen &&
                            isSelectReadingSessionsOpen[sessionIndex] && (
                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: " max-content",
                                  paddingBottom: "1rem",
                                  marginBottom: "1rem",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: " 90%",
                                    top: "0",
                                    left: "0",
                                    background: "#E2C065",
                                    borderRadius: "0.5rem",
                                  }}
                                ></div>
                                <p
                                  style={{
                                    position: "relative",
                                    width: "calc(100% - 1rem)",
                                    height: " 100%",
                                    left: "1rem",
                                    background: "#F5F5DB",
                                    padding: "0.5rem",
                                    transform: "translateY(1rem)",
                                    marginBottom: "0",
                                    borderRadius: "0.5rem",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: session.details,
                                  }}
                                ></p>
                              </div>
                            )}
                        </>
                      )
                    )}
                  </div>
                )}
              <div className="readings-container">
                {cardContents.map((reading, readingIndex) =>
                  selectReading === readingIndex ? null : (
                    <div
                      key={readingIndex}
                      className="reading-container"
                      onClick={() => {
                        setSelectReading(readingIndex);
                        setIsSelectReadingSessionsOpen(
                          reading.sessions.map((e) => false)
                        );
                        document
                          .querySelector("div#readings-session")
                          ?.scrollIntoView();
                      }}
                    >
                      <p className="reading-title">
                        <span className="reading-title-text">
                          {reading.title}
                        </span>
                      </p>
                      <div
                        className="preview-image-container"
                        style={{
                          background: reading.color,
                          paddingTop: "clamp(0.3rem, 2vw, 0.8rem)",
                        }}
                      >
                        <img
                          className="previewimages"
                          src={
                            API.getImage === reading.image
                              ? tempImage
                              : reading.image
                          }
                          alt="PLEASE ADD IMAGE IN ADMINPORTAL"
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div
              className={"situations-session ModuleTwo-situations"}
              id="situations-session"
            >
              <p className="session-title">情景研習</p>
              {storyData.map((element: any, index: number) => {
                return (
                  <div>
                    {element.position === "description" && (
                      <p
                        className="session-title-paragraph situation-paragraph"
                        dangerouslySetInnerHTML={{ __html: element?.content }}
                      ></p>
                    )}
                  </div>
                );
              })}
              <div className="story-cards-container">
                <img className="story-card1" src={card1} />
                {currentStoryBoard + 1 < storyImage.length && (
                  <img
                    className={`next-button ${
                      isTouchDevice ? "touch-device" : "non-touch-device"
                    }`}
                    src={nextButton}
                    onClick={() => {
                      setCurrentStoryBoard((prevState) => {
                        return prevState + 1 >= storyImage.length
                          ? prevState
                          : prevState + 1;
                      });
                    }}
                  />
                )}
                {currentStoryBoard > 0 && (
                  <img
                    className={`prev-button ${
                      isTouchDevice ? "touch-device" : "non-touch-device"
                    }`}
                    src={prevButton}
                    onClick={() => {
                      setCurrentStoryBoard((prevState) => {
                        return prevState === 0 ? prevState : prevState - 1;
                      });
                    }}
                  />
                )}
                <p className="story-number">{currentStoryBoard + 1}</p>
                <div
                  className={`story-content ${
                    isStoryCardUpdated ? "fadeIn" : ""
                  }`}
                >
                  <div className="story-content-img-container">
                    <img
                      className="story-content-img"
                      src={storyImage[currentStoryBoard]?.url}
                    />
                  </div>
                  <p
                    className="story-content-text"
                    dangerouslySetInnerHTML={{
                      __html: storyData[currentStoryBoard + 1]?.content,
                    }}
                  ></p>
                </div>
                {storyImage.length - currentStoryBoard > 1 && (
                  <img className="story-card2" src={card2} />
                )}
                {storyImage.length - currentStoryBoard > 2 && (
                  <img className="story-card3" src={card3} />
                )}
                {storyImage.length - currentStoryBoard > 3 && (
                  <img className="story-card4" src={card4} />
                )}
              </div>
              <div className="story-cards-progress-bar">
                {storyImage.length > 0 && (
                  <div style={{ width: "6.05%" }}>
                    <img
                      className="progress-bar-image"
                      src={progress1y}
                      onClick={() => {
                        setCurrentStoryBoard(0);
                      }}
                    />
                  </div>
                )}
                {storyImage.length > 1 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 0 ? (
                      <img
                        className="progress-bar-image"
                        src={progress2y}
                        onClick={() => {
                          setCurrentStoryBoard(1);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress2n}
                        onClick={() => {
                          setCurrentStoryBoard(1);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 2 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 1 ? (
                      <img
                        className="progress-bar-image"
                        src={progress3y}
                        onClick={() => {
                          setCurrentStoryBoard(2);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress3n}
                        onClick={() => {
                          setCurrentStoryBoard(2);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 3 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 2 ? (
                      <img
                        className="progress-bar-image"
                        src={progress4y}
                        onClick={() => {
                          setCurrentStoryBoard(3);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress4n}
                        onClick={() => {
                          setCurrentStoryBoard(3);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 4 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 3 ? (
                      <img
                        className="progress-bar-image"
                        src={progress5y}
                        onClick={() => {
                          setCurrentStoryBoard(4);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress5n}
                        onClick={() => {
                          setCurrentStoryBoard(4);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 5 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 4 ? (
                      <img
                        className="progress-bar-image"
                        src={progress6y}
                        onClick={() => {
                          setCurrentStoryBoard(5);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress6n}
                        onClick={() => {
                          setCurrentStoryBoard(5);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 6 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 5 ? (
                      <img
                        className="progress-bar-image"
                        src={progress7y}
                        onClick={() => {
                          setCurrentStoryBoard(6);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress7n}
                        onClick={() => {
                          setCurrentStoryBoard(6);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 7 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 6 ? (
                      <img
                        className="progress-bar-image"
                        src={progress8y}
                        onClick={() => {
                          setCurrentStoryBoard(7);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress8n}
                        onClick={() => {
                          setCurrentStoryBoard(7);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 8 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 7 ? (
                      <img
                        className="progress-bar-image"
                        src={progress9y}
                        onClick={() => {
                          setCurrentStoryBoard(8);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress9n}
                        onClick={() => {
                          setCurrentStoryBoard(8);
                        }}
                      />
                    )}
                  </div>
                )}
                {storyImage.length > 9 && (
                  <div style={{ width: "15.5%" }}>
                    {currentStoryBoard > 8 ? (
                      <img
                        className="progress-bar-image"
                        src={progress10y}
                        onClick={() => {
                          setCurrentStoryBoard(9);
                        }}
                      />
                    ) : (
                      <img
                        className="progress-bar-image"
                        src={progress10n}
                        onClick={() => {
                          setCurrentStoryBoard(9);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
            {isExercise && (
              <div
                className={"exercises-session ModuleTwo-exercises"}
                id="exercises-session"
              >
                <p className="session-title">課後練習</p>
                {exerciseData[0]?.map((element: any, index: number) => {
                  return (
                    <div>
                      {element.position === "description" && (
                        <p
                          className="session-title-paragraph"
                          dangerouslySetInnerHTML={{ __html: element?.content }}
                        ></p>
                      )}
                    </div>
                  );
                })}
                {selectExercise !== undefined &&
                  Number.isInteger(selectExercise) && (
                    <div
                      className="expended-exercise-container"
                      style={{
                        background: "rgba(232,200,106,0.5)",
                        padding: "5%",
                        marginBottom: "2rem",
                      }}
                    >
                      <p className="expended-exercise-title">
                        {exerciseData[selectExercise + 1][0]?.content}
                      </p>
                      {exerciseImageData[2 * selectExercise]?.url !== "" && (
                        <iframe
                          className="video-player"
                          // src={"https://www.youtube.com/embed/"+ exerciseImageData[2 * selectExercise]?.url?.split("https://www.youtube.com/watch?v=")?.at(-1)?.split("&")[0]}
                          src={exerciseImageData[2 * selectExercise]?.url || ""}
                          style={{
                            width: "100%",
                            border: "4px solid #6c6e87",
                            borderRadius: "0.5rem",
                          }}
                        />
                      )}
                      <p
                        className="expended-exercise-text"
                        id="12345"
                        dangerouslySetInnerHTML={{
                          __html: exerciseData[selectExercise + 1][1]?.content,
                        }}
                      ></p>
                      {exerciseImageData[2 * selectExercise + 1]?.url !==
                        "" && (
                        <iframe
                          className="video-player"
                          // src={
                          //   "https://www.youtube.com/embed/" +
                          //   exerciseImageData[2 * selectExercise + 1]?.url
                          //     ?.split("https://www.youtube.com/watch?v=")
                          //     ?.at(-1)
                          //     ?.split("&")[0]
                          // }
                          src={
                            exerciseImageData[2 * selectExercise + 1]?.url || ""
                          }
                          style={{
                            width: "100%",
                            border: "4px solid #6c6e87",
                            borderRadius: "0.5rem",
                          }}
                        />
                      )}
                      <p
                        className="expended-exercise-text"
                        id="12345"
                        dangerouslySetInnerHTML={{
                          __html: exerciseData[selectExercise + 1][2]?.content,
                        }}
                      ></p>
                      {exerciseNewImageData[selectExercise]?.url.split(
                        "images"
                      )[1] !== "/" && (
                        <img
                          className="expended-exercise-image"
                          src={exerciseNewImageData[selectExercise]?.url}
                        ></img>
                      )}
                      <p
                        className="expended-exercise-text"
                        id="12345"
                        dangerouslySetInnerHTML={{
                          __html: exerciseData[selectExercise + 1][3]?.content,
                        }}
                      ></p>
                      {exerciseAudioData[selectExercise]?.url.split(
                        "audios"
                      )[1] !== "/" && (
                        <audio
                          controls
                          className="expended-exercise-audio"
                          src={exerciseAudioData[selectExercise]?.url}
                        ></audio>
                      )}
                      <p
                        className="expended-exercise-text"
                        id="12345"
                        dangerouslySetInnerHTML={{
                          __html: exerciseData[selectExercise + 1][4]?.content,
                        }}
                      ></p>
                    </div>
                  )}
                <div className="exercises-container">
                  {exerciseDataFinal.map(
                    (exercise: any, exerciseIndex: number) =>
                      selectExercise === exerciseIndex
                        ? null
                        : exerciseNewImageData[exerciseIndex]?.url.split(
                            "images"
                          )[1] !== "/" && (
                            <div
                              key={exerciseIndex}
                              className="exercise-container"
                              onClick={() => {
                                setSelectExercise(exerciseIndex);
                                document
                                  .querySelector("div.exercises-session")
                                  ?.scrollIntoView();
                              }}
                            >
                              <div className="video-preview-image-container">
                                {/* <img className="play_button" src={playButton} /> */}
                                <img
                                  className="video-preview-image"
                                  src={exerciseNewImageData[exerciseIndex]?.url}
                                />
                              </div>
                              <p className="exercise-title">
                                {exercise[0]?.content}
                              </p>
                            </div>
                          )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ModuleThree;
