import React, { useState, useEffect } from "react";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import faultIcon from "./icon _info circle.webp";
import eye from "./User Profile-icon eye.webp";
import slashEye from "./User Profile-icon eye slash.webp";
import "./Login.css";
import API from "../helper/ApiHelper";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import { setRole } from "../roleSlice";
import { setNickname } from "../nicknameSlice";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState("" as string);
  const [password, setPassword] = useState("" as string);
  const [showPassword, setShowPassword] = useState(false as boolean);
  const [emailIsValid, setEmailIsValid] = useState(false as boolean);
  const [passwordIsValid, setPasswordIsValid] = useState(false as boolean);
  const [submitting, setSubmitting] = useState(false as boolean);
  const [logging, setLogging] = useState(false as boolean);
  const [error, setError] = useState("" as string);
  const [successAlert, setSuccessAlert] = useState(false as boolean);
  const [failureAlert, setFailureAlert] = useState(false as boolean);
  // language setting
  const [langLabel, setLangLabel] = useState(0 as number);
  const language = useSelector((state: RootState) => state.language.language);
  const [lanString, setLanString] = useState([] as any);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  const [sessionDate, setSessionDate] = useState([
    {
      title: "Login",
      paragraph:
        "Join the 12-week 'Facilitator-guided Acceptance and Commitment Bibliotherapy' to improve parenting skills, communication with children, and address mental health issues.",
      fieldTitle1: "Email",
      fieldTitle2: "Password",
      successAlert: "Success Login",
      errorAlert1: "Please input email",
      errorAlert2: "Password at least 8 character",
      errorAlert3: "Invalid email or passwords",
      button1: "Forget Password?",
      button2: "Login",
    },
    {
      title: "登入",
      paragraph:
        "請在此登入為期十二個星期的「由受訓導師帶領及指導的網上親職培訓及心理健康計劃」。此計劃旨在幫助家長學習有效的親職技能，提高父母與子女之間的溝通和理解，同時關注家長的心理健康問題。",
      fieldTitle1: "電郵地址",
      fieldTitle2: "密碼",
      successAlert: "登錄成功",
      errorAlert1: "請輸入有效的電郵地址",
      errorAlert2: "請輸入有效的密碼",
      errorAlert3: "電子郵件或密碼無效",
      button1: "忘記密碼?",
      button2: "登入",
    },
  ] as any[]);
  const [messageIndex, setMessageIndex] = useState<number>(0);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailIsValid(emailRegex.test(event.target.value));
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFailureAlert(false);
    setPassword(event.target.value);
    setPasswordIsValid(event.target.value.length >= 8);
  };

  const handleShowPassword = (event: React.MouseEvent<HTMLImageElement>) => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    if (emailIsValid && passwordIsValid) {
      try {
        const res = await axios
          .post(API.login, {
            email: email,
            password: password,
          })
          .catch((err) => console.error("err", err));

        // console.log(typeof res);
        if (res !== undefined) {
          // get token and store into cookie
          const token: any = jwtDecode(res.data.res);
          Cookies.set("jwt", res.data.res, {
            expires: token.expires / 3600000 / 24,
          });
          // display alert for 3s
          setSuccessAlert(true);
          setEmail("");
          setPassword("");
          const timeoutId = setTimeout(() => {
            setSuccessAlert(false);
          }, 3000);
          setLogging(true);
          // go to user profile page
          // window.location.href = "./user-profile";
          // Post login handling
          didLogin(token.role, token.nickname);
        } else {
          // remove login information if login failure
          Cookies.remove("jwt");
          setError("Network error");
          // display alert for 3s
          setFailureAlert(true);
          const timeoutId = setTimeout(() => {
            setFailureAlert(true);
          }, 3000);
          setLogging(false);
        }
      } catch (error) {
        // remove login information if login failure
        Cookies.remove("jwt");
        setError("Invalid username or password");
        // display alert for 3s
        setFailureAlert(true);
        setLogging(false);
      }
    }
    setSubmitting(true);
  };

  function didLogin(role: string | null, nickname: string | null) {
    // console.log(`role: ${role}, nickname: ${nickname}`)
    if (role) {
      dispatch(setRole(role));
    }
    if (nickname) {
      dispatch(setNickname(nickname));
    }
    switch (role) {
      case "participant":
        window.location.href = "./guided-act-modules-for-parents";
        break;
      case "trainee":
        window.location.href =
          "./professional-training#training-resource-container";
        break;
      default:
        console.error(`Unknown role: ${role}, nickname: ${nickname}`);
        window.location.href = "./user-profile";
        break;
    }
  }

  const handleMessageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    direction: number
  ) => {
    if (direction === 0) return;
    const step = direction < 0 ? -1 : 1;
    let ind = messageIndex + step;
    if (ind < 0) ind = 2;
    if (ind > 2) ind = 0;
    setMessageIndex(ind);
  };

  // change language label
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLangLabel(1);
      setLanString("/ch");
      document.title = "登入 - ACTuwise";
    } else {
      setLangLabel(0);
      setLanString("/en");
      document.title = "Login - ACTuwise";
    }
  }, [language]);

  useEffect(() => {
    if (localStorage.getItem("logging")) {
      setLogging(true);
    } else {
      setLogging(false);
    }

    // Prepare keyboard listener of Enter key
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("login-button")?.click();
      }
    };
    // Reg
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      // Remove
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="login-wrapper">
      <div className="login-header">
        <Header2 url="/login" />
      </div>
      {successAlert && (
        <div className="login-alert">
          <p className="login-success-alert">
            {sessionDate?.[langLabel]?.successAlert}
          </p>
        </div>
      )}
      {/* {failureAlert && <div className="login-alert"><p className="login-failure-alert">錯誤的用戶名或密碼</p></div>} */}
      <div className="login-container">
        <div className="login-box">
          <div className="login-box-left">
            <p className="login-box-left-para">
              {sessionDate?.[langLabel]?.fieldTitle1}
            </p>
            <input
              className="login-box-left-input"
              type="email"
              onChange={handleEmailChange}
              value={email}
              required
            />
            <p
              className="login-box-left-validation-alert"
              style={{
                visibility: !emailIsValid && submitting ? "visible" : "hidden",
              }}
            >
              {sessionDate?.[langLabel]?.errorAlert1}
            </p>
            <p className="login-box-left-para">
              {sessionDate?.[langLabel]?.fieldTitle2}
            </p>
            <div>
              <input
                className="login-box-left-input"
                type={showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
                value={password}
                required
                minLength={8}
              />
              <img
                className="login-box-left"
                src={showPassword ? eye : slashEye}
                alt=""
                onClick={handleShowPassword}
              />
            </div>

            <div className="login-box-left-validation-alert-container">
              {failureAlert ? (
                <div className="login-failure-alert">
                  <img src={faultIcon} />
                  <p>{sessionDate?.[langLabel]?.errorAlert3}</p>
                </div>
              ) : (
                <p
                  className="login-box-left-validation-alert"
                  style={{
                    visibility:
                      (!passwordIsValid && submitting) || failureAlert
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {sessionDate?.[langLabel]?.errorAlert2}
                </p>
              )}
            </div>

            <a
              className="login-box-left-forget-button-container"
              href={lanString + "/forget-password"}
            >
              <p className="login-box-left-forget-button">
                {sessionDate?.[langLabel]?.button1}
              </p>
            </a>
            <button
              id="login-button"
              className="login-box-left-login-button"
              type="submit"
              onClick={handleSubmit}
            >
              {sessionDate?.[langLabel]?.button2}
            </button>
          </div>
          <div className="login-box-right">
            <div className="login-message-header-container">
              <h1 className="login-box-right-title">
                {sessionDate?.[langLabel]?.title}
              </h1>
              <button
                className="button arrow-button"
                type="submit"
                onClick={(e) => {
                  handleMessageChange(e, -1);
                }}
              >
                &larr;
              </button>
              <button
                className="button arrow-button"
                type="submit"
                onClick={(e) => {
                  handleMessageChange(e, 1);
                }}
              >
                &rarr;
              </button>
            </div>
            {language === "en" && (
              <p className="login-box-right-para" style={{marginBottom: '0.5rem'}}>（For registered parents and ACT training participants）</p>
            )}
            {language === "zh_hant" && (
              <p className="login-box-right-para">（適用於已註冊的家長及ACT培訓人士）</p>
            )}
            {/* Experience a 12-week Facilitator-guided Acceptance and Commitment
              Bibliotherapy program designed to enhance your parenting skills
              and improve your mental health. We invite you to join us and
              embark on this transformative journey. */}
            {messageIndex === 0 && language === "en" && (
              <p className="login-box-right-para fadeIn">
                For parents who have completed registration, we sincerely
                welcome you to join this journey: Please log in here to
                participate in our twelve-week 'Facilitator-guided Acceptance
                and Commitment Bibliotherapy (FAB)' online workshop. This
                workshop will assist you in exploring and accepting your
                emotions and feelings with an open mind, and guide you to
                discover and practice your life values.
              </p>
            )}
            {messageIndex === 1 && language === "en" && (
              <p className="login-box-right-para fadeIn">
                For those who have completed the registration for ACT training,
                we look forward to your participation: Please log in here, where
                you will be able to access a variety of training materials that
                we have meticulously prepared, leading you to deeply understand
                and practice the concepts and techniques of Acceptance and
                Commitment Therapy.
              </p>
            )}
            {messageIndex === 2 && language === "en" && (
              <p className="login-box-right-para fadeIn">
                If you are curious about participating in the 'Facilitator-guided Acceptance and Commitment Bibliotherapy' program, or are interested in ACT training materials, we sincerely invite you to contact us via email at <a href="mailto:ACTuwise@cuhk.edu.hk">ACTuwise@cuhk.edu.hk</a>. We look forward to exploring this meaningful journey with you.
              </p>
            )}
            {messageIndex === 0 && language === "zh_hant" && (
              <p className="login-box-right-para fadeIn">
                對於已完成報名的家長們，我們誠摯的歡迎您加入這個旅程：<br/>
                請在此登入，參與我們為期十二周的「導師引導的接納與承諾閱讀治療 (FAB)」網上工作坊。這個工作坊將協助您以開放的心態探索和接納自己的情緒和感受，並引領您發現並實踐您的生活價值。
              </p>
            )}
            {messageIndex === 1 && language === "zh_hant" && (
              <p className="login-box-right-para fadeIn">
                對於已完成報名的ACT培訓者們，我們期待您的參與：<br/>
                請在此登入，您將能夠獲取我們精心準備的各種培訓資料，帶領您深入理解並實踐接納與承諾治療的理念與技巧。
              </p>
            )}
            {messageIndex === 2 && language === "zh_hant" && (
              <p className="login-box-right-para fadeIn">
                如果您對參與「導師引導的接納與承諾閱讀治療」計劃感到好奇，或者對ACT培訓材料感興趣，我們誠摯地邀請您透過電郵<a href="mailto:ACTuwise@cuhk.edu.hk">ACTuwise@cuhk.edu.hk</a>與我們聯絡。我們期待與您一起，共同探索這個有意義的旅程。
              </p>
            )}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
