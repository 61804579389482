import React, { useEffect, useState } from "react";
import "../SectionBar/SectionBar.css";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import config from "../config";

function SectionBarElement(props: { sections: { name: string; url: string }[] }) {

  const [currentUrl, setCurrentUrl] = useState(
  window.location.href.split("3000").at(-1)
  );

  useEffect(() => {
  const handleScroll = () => {
    const sectionOffsets = props.sections.map((section) => ({
      offsetTop: section.url.startsWith("#")
        ? (document.querySelector(section.url) as HTMLElement)?.offsetTop
        : null,
      url: section.url,
    }));
    const current = sectionOffsets
      .reverse()
      .find(
        ({ offsetTop }) => offsetTop && window.pageYOffset + 1 >= offsetTop
      )?.url;

    setCurrentUrl(current || window.location.href.split("3000").at(-1));
  };

  window.addEventListener("scroll", handleScroll, { passive: true });

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
  }, [props.sections]);

  function handleOnClick(url: string) {
  if (currentUrl === url) {
    return;
  }

  if (url.startsWith("#")) {
    const element = document.querySelector(url);
    element && element.scrollIntoView({ behavior: "smooth" });
  } else {
    window.location.href = url;
  }
  }

  const isPhone = window.matchMedia("(max-width: 600px)").matches;

  useEffect(() => {
  if (isPhone) {
    const sectionBar = document.querySelector(".section-bar") as HTMLElement;
    let prevScrollPos = window.pageYOffset;

    window.addEventListener("scroll", () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > window.innerHeight * 0.4) {
        if (prevScrollPos > currentScrollPos) {
          sectionBar?.classList.add("scroll-up");
        } else {
          sectionBar?.classList.remove("scroll-up");
        }
      } else {
        sectionBar?.classList.remove("scroll-up");
      }

      prevScrollPos = currentScrollPos;
    });
  }
  }, [isPhone]);

  return (
  <div className="section-bar">
    <div className="section-bar-container">
      {props.sections.map((section, index) => (
        <React.Fragment key={index}>
        <div
          className={
            currentUrl === section.url ? "section current" : "section"
          }
          key={section.name}
          onClick={() => handleOnClick(section.url)}
        >
          {section.name}
        </div>
        </React.Fragment>
      ))}
    </div>
  </div>
  );
  }



export default SectionBarElement;