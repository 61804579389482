// eslint-disable-next-line
export default {
  basename: "/cms/",
  /* PROD */
  // api_base_url:   '',
  // image_base_url: '',
  // cms_base_url:   '',
  // user_base_url:  '',
  /* DEV 2 */
  // api_base_url:   '',
  // image_base_url: '',
  // cms_base_url:   '',
  // user_base_url:  '',
  // /* Local */
  api_base_url: "",
  image_base_url: "",
  cms_base_url: "",
  // user_base_url: "https://cms.actweb.dev.hkrnd.com/api/graphql",
  // scottComp_base_url: "http://10.1.0.23:3000",
  // backend_base_url: "http://localhost:3000/api/graphql",
  user_base_url:
    `${process.env.REACT_APP_SERVER_DOMAIN}${process.env.REACT_APP_API_PATH}` ||
    "http://localhost:3000/api/graphql",
  scottComp_base_url:
    `${process.env.REACT_APP_SERVER_DOMAIN}${process.env.REACT_APP_API_PATH}` ||
    "http://localhost:3000/api/graphql",
  backend_base_url:
    `${process.env.REACT_APP_SERVER_DOMAIN}${process.env.REACT_APP_API_PATH}` ||
    "http://localhost:3000/api/graphql",
  home_url: "/portfolio",
  theme: "light",
  rtlLayout: false,
  i18n: "en",
  googleAnalyticsApiKey: process.env.REACT_APP_GA_API_KEY || "G-0QZQ9G6BWJ",
  googleMapsApiKey: "",
  isEnableLangTradChi: process.env.REACT_APP_IS_ENABLE_LANG_TRAD_CHI || false,
  isEnableLangSwitch: process.env.REACT_APP_IS_ENABLE_LANG_SWITCH || false,
  versionNum: "1.0.5",
};
