import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import "./GetInvolved.css";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

function GetInvolved() {
  const [data, setData] = useState([] as any);
  const [pagetitle, setPagetitle] = useState([] as any);
  const [subtitle, setSubtitle] = useState([] as any);
  const [loadingText, setLoadingText] = useState("Loading");
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  async function getData() {
    const request = `{menuBar(where: $where) {
      id,
      title,
      parentContent(orderBy: $orderBy) {
        title
        session
        template
        id
        contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }
        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput! ) ${request}`,
      variables: {
        where: { title: "Get Involved" },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.menuBar.parentContent: ",
        //   res.data.data.menuBar.parentContent
        // );
        setData(res.data.data.menuBar.parentContent);
        setPagetitle(res.data.data.menuBar.parentContent[0].contents);
        setSubtitle(res.data.data.menuBar.parentContent[1].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  const language = useSelector((state: RootState) => state.language.language);
  const [lanString, setLanString] = useState([] as any);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    getData();
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "加入我們 - ACTuwise"; // Update the document title on mount
      setLanString("/ch");
    } else {
      setLoadingText("Loading");
      document.title = "Get Involved - ACTuwise"; // Update the document title on mount
      setLanString("/en");
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (pagetitle.length && subtitle.length) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [pagetitle, subtitle]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="get-involved-wrapper">
      <Header2 url="/get-involved" />
      <div className="container">
        <div className="get-involved-title">
          {pagetitle.map((e: any, idx: number) => {
            // console.log(e.content);
            return (
              <div key={idx}>
                {language === "en" &&
                  e.langLabel[0].lang === language &&
                  e.position === "pageTitle" && (
                    <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
                {language === "zh_hant" &&
                  e.langLabel[0].lang === language &&
                  e.position === "pageTitle" && (
                    <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
              </div>
            );
          })}
        </div>
        <div className="get-involved-content">
          <div className="grid-item blue" />
          <div className="grid-item green">
            {pagetitle.map((e: any, idx: number) => {
              // console.log(e.content);
              return (
                <div key={idx}>
                  {language === "en" &&
                    e.langLabel[0].lang === language &&
                    e.position === "pageTitle" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language &&
                    e.position === "pageTitle" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </div>
          <div className="grid-item red" />
          <Link
            className="grid-item yellow"
            to={lanString + "/get-involved/position-available"}
          >
            {subtitle.map((e: any, idx: number) => {
              // console.log(e.content);
              return (
                <div key={idx}>
                  {language === "en" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle1" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle1" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </Link>
          <div className="grid-item purple" />
          <Link
            className="grid-item orange"
            to={lanString + "/get-involved/volunteer-and-internship"}
          >
            {subtitle.map((e: any, idx: number) => {
              // console.log(e.content);
              return (
                <div key={idx}>
                  {language === "en" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle2" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle2" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </Link>
          <div className="grid-item pink" />
          <Link
            className="grid-item teal"
            to={lanString + "/get-involved/collaboration"}
          >
            {subtitle.map((e: any, idx: number) => {
              // console.log(e.content);
              return (
                <div key={idx}>
                  {language === "en" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle3" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle3" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </Link>
          <div className="grid-item gray" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GetInvolved;
