import { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import "./Internship.css";
import React from "react";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

function Internship() {
  const [data, setData] = useState([] as any);
  const [title, setTitle] = useState([] as any);
  //   const [pagetitle, setPagetitle]=useState([]as any);
  //   const [subtitle,setSubtitle]=useState([]as any);
  const [description, setDescription] = useState([] as any);
  const [loadingText, setLoadingText] = useState("Loading");
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  const [card, setCard] = useState([]);
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  async function getData() {
    const request = `{childMenu(where: $where) {
      id,
      title,
      childContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2){
          url
          priority
          category
          id
          title
          type
        }
        id
        contents(orderBy: $contentsOrderBy2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
        }
        videos(orderBy: $videosOrderBy2) {
          url
          priority
          category
          title
          id
          type
        }
        audios (orderBy: $audiosOrderBy2) {
          url
          priority
          category
          title
          id
          type
        }
        pdf(orderBy: $pdfOrderBy2){
          originalFileName
          position
          priority
          title
          url
          category
          id
        }
        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!, $audiosOrderBy2: [AudioDetailOrderByInput!]!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$pdfOrderBy2: [PdfDetailOrderByInput!]!) ${request}`,
      variables: {
        where: { title: "Volunteer" },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        audiosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        videosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        pdfOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.childMenu.childContent: ",
        //   res.data.data.childMenu.childContent
        // );
        // temp filter out the new data first, suppose to add this into each card using category to match which card display position
        // let cardPositionArr = [] as any;
        // res.data.data.childMenu.childContent.forEach((e: any) => {
        //   for (let i = e.contents.length - 1; i >= 0; i--) {
        //     if (e.contents[i].position === "cardPosition") {
        //       cardPositionArr.push(e.contents[i]);
        //       e.contents.splice(i, 1);
        //     }
        //   }
        // });
        // console.log("stored new data in here:", cardPositionArr);
        let cardIDList = [] as any;
        res.data.data.childMenu.childContent[1].contents.forEach((e: any) => {
          cardIDList.push({ id: e.category });
        });
        let totalList = cardIDList.reduce((acc: any, curr: any) => {
          let key = `${curr.id}`;
          if (key in acc) {
            acc[key]++;
          } else {
            acc[key] = 1;
          }
          return acc;
        }, {});
        let cardList = [] as any;
        for (const [key, value] of Object.entries(totalList)) {
          cardList.push({
            cardID: key,
            title: "",
            titleEng: "",
            content: "",
            contentEng: "",
            position: 0,
          });
        }
        cardList.forEach((e: any) => {
          res.data.data.childMenu.childContent[1].contents.forEach(
            (content: any) => {
              if (content.category === e.cardID) {
                if (
                  content.position === "title" &&
                  content.langLabel[0].lang === "zh_hant"
                ) {
                  e.title = content.content;
                } else if (
                  content.position === "title" &&
                  content.langLabel[0].lang === "en"
                ) {
                  e.titleEng = content.content;
                } else if (
                  content.position === "content" &&
                  content.langLabel[0].lang === "zh_hant"
                ) {
                  e.content = content.content;
                } else if (
                  content.position === "content" &&
                  content.langLabel[0].lang === "en"
                ) {
                  e.contentEng = content.content;
                } else if (content.position === "cardPosition") {
                  e.position = +content.content;
                }
              }
            }
          );
        });
        cardList.sort((a: any, b: any) => a.position - b.position);
        setCard(cardList);
        setData(res.data.data.childMenu.childContent[1].contents);
        setDescription(res.data.data.childMenu.childContent[0].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  async function getTitleData() {
    const request = `{menuBar(where: $where) {
      id,
      title,
      parentContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2, where:$imagesWhere2){
          url
          priority
          category
          id
          title
          type
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2, where:$contentsWhere2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }

        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!) ${request}`,
      variables: {
        where: { title: "Get Involved" },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.menuBar.parentContent: ",
        //   res.data.data.menuBar.parentContent
        // );
        setTitle(res.data.data.menuBar.parentContent[1].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  const language = useSelector((state: RootState) => state.language.language);

  useEffect(() => {
    getData();
    getTitleData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "義工及實習機會 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "Volunteer and Internship - ACTuwise"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (
      //title.length &&
      data.length
    ) {
      setIsLoading(false);
    }
  }, [
    //title,
    data,
  ]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="get-involved-wrapper">
      <Header2 url="/get-involved/volunteer-and-internship" />
      <div className="Int-container">
        <div id="middleSection" className="Int-content">
          <h1 className="Int-title">
            {title.map((e: any, idx: number) => {
              return (
                <div key={idx}>
                  {language === "en" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle2" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle2" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </h1>

          <div className="coo-desc">
            {description.map((e: any, idx: number) => {
              // console.log(e.content);
              return (
                <div key={idx}>
                  {language === "en" && e.langLabel[0].lang === language && (
                    <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </div>

          {card.map((e: any, idx: number) => {
            return (
              <div className={"Int-contents-container"}>
                <div className={"Int-callout-container"}>
                  <p className={"Int-callout-title"}>
                    <div key={idx}>
                      {language === "en" && (
                        <div
                          dangerouslySetInnerHTML={{ __html: e.titleEng }}
                        ></div>
                      )}
                      {language === "zh_hant" && (
                        <div
                          dangerouslySetInnerHTML={{ __html: e.title }}
                        ></div>
                      )}
                    </div>
                  </p>
                  <div className={"Int-callout-description"}>
                    {language === "zh_hant" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                    {language === "en" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.contentEng }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Internship;

//     const currentUrl = location.pathname
//     function handleOnClick(url: string) {
//       if (currentUrl === url) {
//         return;
//       }
//       window.location.href = url;
//     }
//     const [imageArr, setImageArr] = useState([] as any);
//     const [videoArr, setVideoArr] = useState([] as any);
//     const [calloutArr, setCalloutArr] = useState([] as any);
//     const [audioArr, setAudioArr] = useState([] as any);
//     const receivedData = {
//       id: `newCreateSession_`,
//       contents: [
//           {
//               category: "Internship",
//               content: "義工及實習機會",
//               id: "",
//               position: "title",
//               priority: null,
//           },
//           {
//               category: "Internship",
//               content:
//                   '<p style="text-align:center;"><span style="font-size: 20px;">我們歡迎與本地和國際機構進行合作，以促進心理健康的發展。 以下是不同的合作方式：</span></p>',
//               id: "",
//               position: "description",
//               priority: null,
//           },
//           {
//               category: "Internship",
//               content:
//                   "成為心理支援義工",
//               id: "",
//               position: "card1_title",
//               priority: null,
//           },
//           {
//               category: "Internship",
//               content:
//                   '<p style="text-align:left;"><span>我們的團隊希望能夠組建一個心理支援義工網絡，以支持和幫助更多的同路人。<br/><br/>如果您有意成為朋輩心理支援義工，或有興趣接受接納與承諾治療的培訓，我們非常歡迎您與我們聯繫。請通過電子郵件<a href="mailto:hello@abc.com">hello@abc.com</a>與我們聯繫，並告訴我們您的興趣和想法。我們將提供您所需的培訓和支持，幫助您成為心理支援義工。讓我們攜手合作，為家長和家庭提供更好的支援和幫助。</span></p>',
//               id: "",
//               position: "card1_content",
//               priority: null,
//           },
//       ],
//       images: [],
//       audios: [],
//       videos: [],
//       session: "",
//       sessionImage: "",
//       template: "",
//       title: "合作機會",
//   }
//     const [data, setData] = useState<any>(receivedData);
//     let id = "Internship" //change;

//       useEffect(() => {
//         document.title = "Internship"; // Update the document title on mount
//       }, []);
