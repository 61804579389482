import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import API from "../helper/ApiHelper";
import { setChinese, setEnglish } from "../languageSlice";
import { setNickname } from "../nicknameSlice";
import { setRole } from "../roleSlice";
import { RootState } from "../store";
import slashEye from "./User Profile-icon eye slash.webp";
import eye from "./User Profile-icon eye.webp";
import "./UserProfile.css";
import DecoImg from "./User Profile-bg desktop-piece1.webp";

// interface CustomInputElement extends HTMLInputElement {
//     direction?: 'ltr' | 'rtl' | '';
// }

function UserProfile() {
  const [id, setId] = useState("" as string);
  const [email, setEmail] = useState("" as string);
  const [newNickname, setNewNickname] = useState("" as string);
  const [password, setPassword] = useState("" as string);
  const [inputPassword, setInputPassword] = useState("" as string);
  const [newPassword, setNewPassword] = useState("" as string);
  const [confirmPassword, setConfirmPassword] = useState("" as string);
  const [submitNickname, setSubmitNickname] = useState(false as boolean);
  const [submitPassword, setSubmitPassword] = useState(false as boolean);
  const [validNickname, setValidNickname] = useState(false as boolean);
  const [validNicknameLength, setValidNicknameLength] = useState(
    true as boolean
  );
  const [emailAlert, setEmailAlert] = useState(false as boolean);
  const [validPassword, setValidPassword] = useState(false as boolean);
  const [validPasswordLength, setValidPasswordLength] = useState(
    false as boolean
  );
  const [validNewPasswordLength, setValidNewPasswordLength] = useState(
    false as boolean
  );
  const [confirm, setConfirm] = useState(false as boolean);
  const [showPassword, setShowPassword] = useState(false as boolean);
  const [showNewPassword, setShowNewPassword] = useState(false as boolean);
  const [showConfirmPassword, setShowConfirmPassword] = useState(
    false as boolean
  );
  const [changed, setChanged] = useState([false, false] as boolean[]);
  const [changedNickname, setChangedNickname] = useState(false as boolean);
  const [changedPassword, setChangedPassword] = useState(false as boolean);

  // const inputs = document.querySelectorAll('input') as NodeListOf<CustomInputElement>;

  // nickname setting
  const nickname = useSelector((state: RootState) => state.nickname.nickname);
  const dispatch = useDispatch();
  // language setting
  const [langLabel, setLangLabel] = useState(0 as number);
  const language = useSelector((state: RootState) => state.language.language);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [sessionDate, setSessionDate] = useState([
    {
      fieldTitle1: "Nickname",
      fieldTitle2: "Email",
      button1: "Save change",
      title: "Change Password",
      fieldTitle3: "Password",
      fieldTitle4: "New Password",
      fieldTitle5: "Confirm new password",
      errorAlert1: "Please enter your nickname.",
      errorAlert2: "Nickname cannot exceed 15 characters.",
      errorAlert3: "Email cannot be changed.",
      errorAlert4: "Incorrect password.",
      errorAlert5:
        "Length: Minimum of 8 characters.<br />Mix of Letters: Use both uppercase and lowercase letters.<br />Include a Number: At least one number is required.<br />Add a Symbol: Include one symbol (like !, @, #, $, %, ^, &, *).", //"Use 8 or more characters with a mix of upper & lowercase letters, numbers & symbols.",
      errorAlert6: "Please enter correct new password.",
      button2: "Change password",
      changedNicknameAlert1: "Nickname Changed",
      changedNicknameAlert2: "Error on changing nickname",
      changedPasswordAlert1: "Password Changed",
      changedPasswordAlert2: "Error on changing password",
    },
    {
      fieldTitle1: "暱稱",
      fieldTitle2: "電郵地址",
      button1: "更改",
      title: "更改密碼",
      fieldTitle3: "密碼",
      fieldTitle4: "新密碼",
      fieldTitle5: "確認新密碼",
      errorAlert1: "暱稱不能為空。",
      errorAlert2: "暱稱不能超過 15 個字符。",
      errorAlert3: "電子郵件無法更改",
      errorAlert4: "密碼不正確。",
      errorAlert5:
        "長度：至少 8 個字。<br />字母混合：使用大寫和小寫字母。<br />使用數字：至少需要一個數字。<br />使用符號：包含一個符號（例如!、@、#、 $、%、^、&、*)。",
      errorAlert6: "確認新密碼與新密碼不一致。",
      button2: "更改密碼",
      changedNicknameAlert1: "暱稱已更改",
      changedNicknameAlert2: "更改暱稱出錯",
      changedPasswordAlert1: "密碼已更改",
      changedPasswordAlert2: "更改密碼出錯",
    },
  ] as any[]);

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  const request = `{
    updateWebUser(where: $where, data: $data) {
        id
        nickname
        password {
          isSet
        }
        role
        email
      }
  }`;

  const getData = () => {
    const jwt: string = Cookies.get("jwt") as string;
    const token: any = jwtDecode(jwt);
    // console.log(token);
    setId(token.id);

    // get nickname and data
    const dataRequest = `{
        webUser(where: $where) {
            role
            nickname
            email
        }
    }`;

    const graphqlQuery = {
      operationName: "WebUser",
      query: `query WebUser($where: WebUserWhereUniqueInput!) ${dataRequest}`,
      variables: {
        where: { id: token.id },
      },
    };

    // Get backend data using Axios
    axios({
      url: API.local, // Reference in ApiHelper
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (typeof res != undefined) {
          if (res.status == 200) {
            const { nickname, role, email } = res.data.data.webUser;
            dispatch(setNickname(nickname));
            dispatch(setRole(role));
            setNewNickname(nickname);
            setEmail(email);
          }
        }
      })
      .catch((err) => console.error("err", err));
  };

  const handleNickname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNickname(event.target.value);
    if (event.target.value.length > 15) {
      setValidNicknameLength(false);
    } else {
      setValidNicknameLength(true);
    }
  };

  const handleClickEmail = () => {
    setEmailAlert(true);
    const setTimer = setTimeout(() => {
      setEmailAlert(false);
    }, 3000);
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value);
  };

  const handleNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  const handleShowPassword = (event: React.MouseEvent<HTMLImageElement>) => {
    setShowPassword(!showPassword);
  };

  const handleShowNewPassword = (event: React.MouseEvent<HTMLImageElement>) => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = (
    event: React.MouseEvent<HTMLImageElement>
  ) => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // change nickname
  const handleSubmitNickname = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (newNickname.length == 0 || !validNicknameLength) {
      setValidNickname(false);
      // console.log("long");
    } else {
      // update nickname
      const graphqlQuery = {
        operationName: "UpdateWebUser",
        query: `mutation UpdateWebUser($where: WebUserWhereUniqueInput!, $data: WebUserUpdateInput!)${request}`,
        variables: {
          where: { id: id },
          data: { nickname: newNickname },
        },
      };

      try {
        axios({
          url: API.local, // Reference in ApiHelper
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then((res) => {
            // console.log(res);
            if (typeof res != undefined) {
              switch (res.status) {
                case 200:
                  if (typeof res.data.data.updateWebuse != undefined) {
                    setChangedNickname(true);
                    dispatch(setNickname(newNickname));
                    setValidNickname(true);
                    // display alert for 3s
                    setChanged([true, false]);
                    const timeoutId = setTimeout(() => {
                      setChanged([false, false]);
                      setChangedNickname(false);
                    }, 3000);
                  }
                  break;
                default:
                  // console.log("fail changed nickname");
                  setValidNickname(true);
                  // display alert for 3s
                  setChanged([true, false]);
                  const timeoutId = setTimeout(() => {
                    setChanged([false, false]);
                    setChangedNickname(false);
                  }, 3000);
              }
            }
          })
          .catch((err) => console.error("err", err));
      } catch (err) {
        setValidNickname(true);
        // display alert for 3s
        setChanged([true, false]);
        const timeoutId = setTimeout(() => {
          setChanged([false, false]);
          setChangedNickname(false);
        }, 3000);
      }
    }
    setSubmitNickname(true);
  };

  // change password
  const handleSubmitPassword = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    let submit = true;
    if (inputPassword !== password) {
      // current don't check
      //submit = false;
      //setValidPassword(false);
      setValidPassword(true);
    } else {
      setValidPassword(true);
    }
    if (inputPassword.length < 8) {
      submit = false;
      setValidPasswordLength(false);
    } else {
      setValidPasswordLength(true);
    }
    if (newPassword.length < 8) {
      setValidNewPasswordLength(false);
    } else {
      setValidNewPasswordLength(true);
    }
    if (newPassword !== confirmPassword) {
      submit = false;
      setConfirm(false);
    } else {
      setConfirm(true);
    }

    if (submit) {
      // update password
      const graphqlQuery = {
        operationName: "UpdateWebUser",
        query: `mutation UpdateWebUser($where: WebUserWhereUniqueInput!, $data: WebUserUpdateInput!)${request}`,
        variables: {
          where: { id: id },
          data: { password: confirmPassword },
        },
      };

      try {
        const res = await axios.post(`${API.url}/changePassword`, {
          id: id,
          password: inputPassword,
          newPassword: confirmPassword,
        });

        if (typeof res != undefined) {
          switch (res.status) {
            case 200:
              setChangedPassword(true);
              break;
            default:
          }
        }

        setChanged([false, true]);
        const timeoutId = setTimeout(() => {
          setChanged([false, false]);
          setChangedPassword(false);
        }, 3000);
      } catch (err) {
        setChanged([false, true]);
        const timeoutId = setTimeout(() => {
          setChanged([false, false]);
          setChangedPassword(false);
        }, 3000);
      }
    }

    setSubmitPassword(true);
    setInputPassword("");
    setNewPassword("");
    setConfirmPassword("");
    // display alert for 3s
  };

  // console.log(`${window.location.href.split(".com").at(0)}.com/api/changePassword`)

  useEffect(() => {
    getData();
  }, []);

  // change language label
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLangLabel(1);
      document.title = "個人資料 - ACTuwise";
    } else {
      setLangLabel(0);
      document.title = "User Profile - ACTuwise";
    }
  }, [language]);

  // function updateInputDirection(event: Event) {
  //     inputs.forEach(input => {
  //         const { scrollWidth, clientWidth } = input;
  //         console.log(scrollWidth, clientWidth)

  //         input.direction = scrollWidth > clientWidth ? 'rtl' : '';
  //     });
  // }

  // inputs.forEach(input => {
  //     input.addEventListener('input', updateInputDirection);
  // });

  // window.addEventListener('resize', updateInputDirection);

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const printErrorAlert5 = () => {
    return (
      <p
        style={{ color: "red", margin: 0 }}
        dangerouslySetInnerHTML={{
          __html: sessionDate?.[langLabel]?.errorAlert5,
        }}
      ></p>
    );
  };

  return (
    <div className="user-profile-wrapper">
      {/* Header */}
      <div className="user-profile-header">
        <Header2 url="/user-profile" />
      </div>
      {/* Alert on nickname change */}
      {changed[0] && (
        <div className="user-profile-change-alert">
          <p style={{ background: changedNickname ? "lightgreen" : "red" }}>
            {changedNickname
              ? sessionDate?.[langLabel]?.changedNicknameAlert1
              : sessionDate?.[langLabel]?.changedNicknameAlert2}
          </p>
        </div>
      )}
      {/* Alert on profile change */}
      {changed[1] && (
        <div className="user-profile-change-alert">
          <p style={{ background: changedPassword ? "lightgreen" : "red" }}>
            {changedPassword
              ? sessionDate?.[langLabel]?.changedPasswordAlert1
              : sessionDate?.[langLabel]?.changedPasswordAlert2}
          </p>
        </div>
      )}
      {/* Content */}
      <div className="user-profile-container">
        {/* Box container */}
        <div className="user-profile-box">
          {/* Upper box */}
          <div className="user-profile-box-top">
            {/* Upper box image (that tree image) */}
            <img
              src={DecoImg}
              style={{
                borderTopLeftRadius: "0.75rem",
                borderTopRightRadius: "0.75rem",
                width: "100%",
              }}
              alt={langLabel === 0 ? "deco image" : "裝飾圖"}
            />
            {/* The title container */}
            <div className="user-profile-box-top-bar">
              <h1 className="user-profile-box-top-title">{nickname}</h1>
            </div>
            <div className="user-profile-box-top-body">
              <p className="user-profile-box-para">
                {sessionDate?.[langLabel]?.fieldTitle1}
              </p>
              <input
                className="user-profile-box-input user-profile-box-input-nickname"
                value={newNickname}
                onChange={handleNickname}
              />
              <p
                className="user-profile-alert user-profile-top-alert"
                style={{
                  display:
                    (!validNickname || !validNicknameLength) && submitNickname
                      ? "block"
                      : "none",
                }}
              >
                {validNicknameLength
                  ? sessionDate?.[langLabel]?.errorAlert1
                  : sessionDate?.[langLabel]?.errorAlert2}
              </p>
              <p className="user-profile-box-para">
                {sessionDate?.[langLabel]?.fieldTitle2}
              </p>
              <input
                className="user-profile-box-input"
                value={email}
                readOnly
                onClick={handleClickEmail}
              />
              <p
                className="user-profile-alert user-profile-top-alert"
                style={{ display: emailAlert ? "block" : "none" }}
              >
                {sessionDate?.[langLabel]?.errorAlert3}
              </p>
              <div className="user-profile-button-container">
                <button
                  className="user-profile-box-button user-profile-box-top-button"
                  onClick={handleSubmitNickname}
                >
                  {sessionDate?.[langLabel]?.button1}
                </button>
              </div>
            </div>
          </div>
          {/* Title of bottom box */}
          <h1 className="user-profile-box-bottom-title">
            {sessionDate?.[langLabel]?.title}
          </h1>
          {/* Bottom box */}
          <div className="user-profile-box-bottom">
            <p className="user-profile-box-para">
              {sessionDate?.[langLabel]?.fieldTitle3}
            </p>
            <div className="user-profile-box-bottom-input-container">
              <input
                className="user-profile-box-input user-profile-box-input-password"
                type={showPassword ? "text" : "password"}
                value={inputPassword}
                onChange={handlePassword}
              />
              <img
                className="user-profile-box-bottom-img"
                src={showPassword ? eye : slashEye}
                alt=""
                onClick={handleShowPassword}
              />
            </div>
            <p
              className="user-profile-alert user-profile-bottom-alert"
              style={{
                display:
                  (!validPassword || !validPasswordLength) && submitPassword
                    ? "block"
                    : "none",
              }}
            >
              {validPasswordLength
                ? sessionDate?.[langLabel]?.errorAlert4
                : printErrorAlert5()}
            </p>
            <p className="user-profile-box-para">
              {sessionDate?.[langLabel]?.fieldTitle4}
            </p>
            <div className="user-profile-box-bottom-input-container">
              <input
                className="user-profile-box-input user-profile-box-input-password"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleNewPassword}
              />
              <img
                className="user-profile-box-bottom-img"
                src={showNewPassword ? eye : slashEye}
                alt=""
                onClick={handleShowNewPassword}
              />
              {/* <p style={{paddingLeft: '15px', color: 'red'}}>Use 8 or more characters with a mix of upper & lowercase letters, numbers & symbols.</p> */}
            </div>
            <p
              className="user-profile-alert user-profile-bottom-alert"
              style={{
                display:
                  !validNewPasswordLength && submitPassword ? "block" : "block", // Change to always show
              }}
            >
              {printErrorAlert5()}
            </p>
            <p className="user-profile-box-para">
              {sessionDate?.[langLabel]?.fieldTitle5}
            </p>
            <div className="user-profile-box-bottom-input-container">
              <input
                className="user-profile-box-input user-profile-box-input-password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPassword}
              />
              <img
                className="user-profile-box-bottom-img"
                src={showConfirmPassword ? eye : slashEye}
                alt=""
                onClick={handleShowConfirmPassword}
              />
            </div>
            <p
              className="user-profile-alert user-profile-bottom-alert"
              style={{
                display: !confirm && submitPassword ? "block" : "none",
              }}
            >
              {sessionDate?.[langLabel]?.errorAlert6}
            </p>
            <div className="user-profile-button-container">
              <button
                className="user-profile-box-button user-profile-box-bottom-button"
                onClick={handleSubmitPassword}
              >
                {sessionDate?.[langLabel]?.button2}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default UserProfile;
