import React, { useEffect, useState } from "react";
import "./Header2.css";
// import headerIcon from "./Emblem_of_CU 1.webp";
import headerIcon from "./logo-05-cropped-3.png";
// import siteLogo from "./Logo.png"
// import headerIcon from "./ACTUWISE_LOGO_02_word.png"
import siteLogo from "./ACTUWISE_LOGO_02_logo.png";
import fbIcon from "../Footer/fb2.png";
import igIcon from "./Group 2354.webp";
import twitterIcon from "./x_black.png";
import CustomizedMenus from "./CustomizedMenu";
import axios from "axios";
import config from "../config";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import { RootState, AppDispatch } from "../store";
import { BeatLoader } from "react-spinners";
import { setNickname } from "../nicknameSlice";
import { setRole } from "../roleSlice";
import { fetchHeaderData } from "./fetchHeaderData";
import { Link, useNavigate } from "react-router-dom";
import { setPopTrue, setPopFalse } from "../NewsSlice";
import News from "../News/News";
import { fetchNewsData } from "../News/fetchNewsData";
import { NewsImageDetail } from "../News/News";

export interface headerLink {
  title: string[];
  path: string | "";
}

type Menu = {
  id: string;
  title: string;
  url: string;
  priority: number;
  child: ChildMenu[];
};

type ChildMenu = {
  id: string;
  title: string;
  url: string;
  priority: number;
};

type FullMenu = {
  id: string;
  priority: number;
  title: string;
  parentTitle: string;
  url: string;
  parentID: string;
};

//interface Props{
//  onLanguageChange: (newStatement: string) => void;
//}

interface Header2Props {
  url: string;
}

export const Header2 = (props: Header2Props) => {
  // const [menuTitles, setMenuTitles] = useState<MenuTitlesType[]>([]);
  const [menuTitles, setMenuTitles] = useState([] as any);
  const [isMenuExpand, setIsMenuExpand] = useState<boolean>(false);
  // const [menuParent, setMenuParent] = useState<[Menu]>([] as any);
  const [wholeMenu, setWholeMenu] = useState<[FullMenu]>([] as any);
  const [openList, setOpenList] = useState(false as boolean);
  const isPhone = window.matchMedia("(max-width: 768px)").matches;
  // user setting
  const nickname = useSelector((state: RootState) => state.nickname.nickname);
  const role = useSelector((state: RootState) => state.role.role);

  const [links, setLinks] = useState<headerLink[]>();
  const navigate = useNavigate();
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  let Image = useSelector((state: RootState) => state.News.PopImage);
  let newsimage: NewsImageDetail[] = [] as any;
  fetchNewsData((News) => {
    newsimage = News;
  });
  // console.log(newsimage);
  let popImage = "";
  if (newsimage.length) {
    if (newsimage[0]?.url === "") {
      popImage = "false";
    } else {
      popImage = Image;
    }
  }

  // console.log(popImage)

  // const popImage=useSelector((state:RootState)=>state.News.PopImage);

  const language = useSelector((state: RootState) => state.language.language);
  const dispatch = useDispatch();
  const [lanString, setLanString] = useState([] as any);
  const handleEnglish = () => {
    // const styles = document.getElementsByTagName("style");
    // const stylesToRemove = [];

    // for (let i = 0; i < styles.length; i++) {
    //   const style = styles[i];
    //   if (style.innerHTML.includes("Noto Serif TC")) {
    //     stylesToRemove.push(style);
    //   }
    // }

    // stylesToRemove.forEach((style) => style.remove());
    dispatch(setEnglish());
  };

  const handleChinese = () => {
    // const style = document.createElement("style");
    // style.innerHTML =
    //   'p,a,span,h1,h2,h3,h4,h5,h6  { font-family: "Noto Serif TC", serif;}';
    // document.head.appendChild(style);
    dispatch(setChinese());
  };

  const buttonHandleEnglish = () => {
    dispatch(setEnglish());

    navigate("/en" + props.url);
    window.location.reload();
    // const styles = document.getElementsByTagName("style");
    // const stylesToRemove = [];

    // for (let i = 0; i < styles.length; i++) {
    //   const style = styles[i];
    //   if (style.innerHTML.includes("Noto Serif TC")) {
    //     stylesToRemove.push(style);
    //   }
    // }

    // stylesToRemove.forEach((style) => style.remove());
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const buttonHandleChinese = () => {
    dispatch(setChinese());

    navigate("/ch" + props.url);
    window.location.reload();
    // const style = document.createElement("style");
    // style.innerHTML =
    //   'p,a,span,h1,h2,h3,h4,h5,h6  { font-family: "Noto Serif TC", serif;}';
    // document.head.appendChild(style);
  };

  useEffect(() => {
    if (language === "zh_hant") {
      handleChinese();
      setLanString("/ch");
    } else {
      handleEnglish();
      setLanString("/en");
    }
  }, [language]);

  useEffect(() => {
    fetchHeaderData((footer) => {
      // console.log(footer);f
      setLinks(footer);
    });
  }, []);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // window.addEventListener('load', () => {
  //   const pointer = document.getElementById('pointer');
  //   const centerX = window.innerWidth / 2;
  //   const centerY = window.innerHeight / 2;
  //   pointer.style.left = `${centerX}px`;
  //   pointer.style.top = `${centerY}px`;
  // });

  //useEffect(()=>{
  //  localStorage.setItem('languagekey',language)
  // },[language])

  //function handleClickChange(language:string){
  //  onLanguageChange(language);
  //};

  async function menuTitle() {
    const AllMenuItems = `{menuBars(orderBy: $orderBy) {
        id,
        title,
        url,
        priority,
        child(orderBy: $childOrderBy) {
          id,
          title,
          url,
          priority,
          displayName,
          displayNameEng,
        },
        displayName,
        displayNameEng,
      }
    }`;
    //   const AllMenuItems = `{menuBars(orderBy: $orderBy) {
    //     id,
    //     title,
    //     url,
    //     priority,
    //     child(orderBy: $childOrderBy) {
    //       id,
    //       title,
    //       url,
    //       priority,
    //     },
    //   }
    // }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($orderBy: [MenuBarOrderByInput!]!, $childOrderBy:[ChildMenuOrderByInput!]!) ${AllMenuItems}`,
      variables: {
        orderBy: [
          {
            priority: "asc",
          },
        ],
        childOrderBy: [
          {
            priority: "asc",
          },
        ],
      },
    };
    axios({
      url: config.user_base_url,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // setMenuParent(res.data.data.menuBars);
        let sortedList = res.data.data.menuBars.map((e: Menu) => {
          return e;
        });
        let wholeList = [] as any;
        // console.log(sortedList)
        for (let i = 0; i < sortedList.length; i++) {
          // Manually  delete landing page button from header list
          // Coz client already input some data in PROD site, while PROD didn't have landing in menu
          // So we need to use sortList[i].title instead of sortList[i].id
          // 21062023 Update: Added condition to stop pushing Facilitator-Guided ACT child to wholeMenu
          if (
            sortedList[i].title !== "Landing" &&
            sortedList[i].title !== "Footer" &&
            sortedList[i].title !== "News"
          ) {
            if (
              sortedList[i].child.length >= 1 &&
              sortedList[i].title !== "Facilitator-Guided ACT" &&
              sortedList[i].title !== "Training"
            ) {
              wholeList.push({
                id: sortedList[i].id,
                priority: sortedList[i].priority,
                title: sortedList[i].title,
                url: sortedList[i].url,
                parentTitle: "",
                parentID: "",
              });
              sortedList[i].child.forEach((e: any) =>
                wholeList.push({
                  id: e.id,
                  priority: sortedList[i].priority + "." + e.priority,
                  title: e.title,
                  url: e.url,
                  parentTitle: sortedList[i].title,
                  parentID: sortedList[i].id,
                })
              );
            } else {
              wholeList.push({
                id: sortedList[i].id,
                priority: sortedList[i].priority,
                title: sortedList[i].title,
                url: sortedList[i].url,
                parentTitle: "",
                parentID: "",
              });
            }
          }
        }
        for (let i = 0; i < res.data.data.menuBars.length; i++) {
          if (res.data.data.menuBars[i].title == "Landing") {
            delete res.data.data.menuBars[i];
            continue;
          }

          if (res.data.data.menuBars[i].title == "Footer") {
            delete res.data.data.menuBars[i];
            continue;
          }

          if (res.data.data.menuBars[i].title == "Facilitator-Guided ACT") {
            res.data.data.menuBars[i].child = [];
            continue;
          }

          if (res.data.data.menuBars[i].title == "Training") {
            res.data.data.menuBars[i].child = [];
            continue;
          }
        }
        // console.log("whole", wholeList);

        const menuBars = res.data.data.menuBars.filter(
          (item: any) => item.title !== "News"
        );
        if (role != "participant") {
          menuBars.forEach((e: any, idx: number) => {
            if (e.title === "Facilitator-Guided ACT") {
              menuBars.splice(idx, 1);
            }
          });
        }
        // console.log(menuBars);
        setWholeMenu(wholeList);
        setMenuTitles(menuBars);
        // console.log("menuBars", menuTitles);
      })
      .catch((err) => console.log("err", err));
  }

  const handleOpen = () => {
    setOpenList(!openList);
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    Cookies.remove("role");
    localStorage.removeItem("logging");
    setOpenList(false);
    dispatch(setNickname(""));
    dispatch(setRole(""));
    navigate(lanString + "/login");
  };

  useEffect(() => {
    menuTitle();
    // setMenuTitles([
    //   { title: "活動", url: "/act", child: [] },
    //   { title: "ACT指引", url: "/act", child: [] },
    //   { title: "研究", url: "/research", child: [] },
    //   { title: "甚麼是ACT", url: "/act", child: [] },
    //   { title: "關於我們", url: "/meetus", child: [] },
    //   { title: "宣傳推廣", url: "/publicity", child: [] },
    //   { title: "加入我們", url: "/act", child: [] },
    // ]);
  }, []);

  const [contentTitle, setContentTitle] = useState("");
  const [transformX, setTransformX] = useState(100);
  const [isEng, setIsEng] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");

  const [date, setDate] = useState(new Date().getDate());

  useEffect(() => {
    setIsLoading(true);
    setIsMenuExpand(false);

    if (language === "en") {
      setContentTitle("Content");
      setTransformX(60);
      setIsEng(true);
      setLoadingText("Loading");
    } else {
      setContentTitle("目錄");
      setTransformX(130);
      setIsEng(false);
      setLoadingText("加載中");
    }

    document.body.setAttribute("style", "overflow:hidden;");
    setTimeout(() => {
      setIsLoading(false);
      document.body.setAttribute("style", "overflow:visible;");
    }, 300);

    if (
      typeof localStorage.getItem("date") === null ||
      localStorage.getItem("date") !== `${date}`
    ) {
      dispatch(setPopTrue());
      localStorage.setItem("date", `${date}`);
    }

    setInterval(() => {
      setDate(new Date().getDate());
    }, 60000);
    // console.log(localStorage.getItem("date"))
  }, [date, language]);
  {
    /* light yellow #f5df8f */
  }
  {
    /* navy blue #1A237E */
  }
  {
    /* grey #BDBDBD*/
  }
  {
    /* dark green #1E5631 */
  }
  {
    /* light pink #F8BBD0 */
  }
  // Loading UI Control const currentTime = new Date();
  // if (isLoading) {
  //   return (
  //     <Loading loadingText={loadingText} />
  //   );
  // }

  return (
    <>
      {/* {popImage==="true"?(
      <News/>
    ):(
      <></>
    )} */}
      <div id="header-top">
        <div id="header-top-left">
          <a href={lanString + "/home"}>
            <img src={siteLogo} alt="Site logo" className="site-logo" />
            <img id="header-icon" src={headerIcon} alt="" />
          </a>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p> */}
        </div>

        {isPhone ? (
          <></>
        ) : (
          <div id="header-top-right">
            <div id="header-social-icon-container">
              {links && links[0] && links[0].path !== "" && (
                <a href={links[0].path} target="_blank">
                  <img className="social-icon-header" src={fbIcon} alt="" />
                </a>
              )}
              {links && links[1] && links[1].path !== "" && (
                <a href={links[1].path} target="_blank">
                  <img className="social-icon-header" src={igIcon} alt="" />
                </a>
              )}
              {links && links[2] && links[2].path !== "" && (
                <a href={links[2].path} target="_blank">
                  <img
                    className="social-icon-header"
                    src={twitterIcon}
                    alt=""
                  />
                </a>
              )}
              {/* <a href="">
                <img className="header-social-icon" src={fbIcon} alt="" />
              </a>
              <a href="">
                <img className="header-social-icon" src={igIcon} alt="" />
              </a>
              <a href="">
                <img className="header-social-icon" src={twitterIcon} alt="" />
              </a> */}
            </div>
            {config.isEnableLangSwitch && (
              <div id="languageBtn-container">
                <button
                  style={{
                    textDecoration: language === "en" ? "underline" : "none",
                  }}
                  className="languageBtn"
                  onClick={buttonHandleEnglish}
                >
                  Eng
                </button>
                &nbsp;/&nbsp;
                <button
                  style={{
                    textDecoration: language === "en" ? "none" : "underline",
                  }}
                  className="languageBtn"
                  onClick={buttonHandleChinese}
                >
                  中文
                </button>
              </div>
            )}
            {role ? (
              <div>
                <div className="loginBtn-container">
                  <button onClick={handleOpen} className="login-button">
                    {nickname}
                  </button>
                  {openList && (
                    <div className="header-top-right-button-container">
                      <span />
                      <a href={lanString + "/user-profile"}>
                        <button>{isEng ? "Profile" : "個人資料"}</button>
                      </a>
                      {/* <a href={lanString + "/login"}> */}
                      <button onClick={handleLogout}>
                        {isEng ? "Logout" : "登出"}
                      </button>
                      {/* </a> */}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="loginBtn-container">
                <a href={lanString + "/login"}>
                  <button className="login-button">
                    {isEng ? "Login" : "登入"}
                  </button>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {isPhone ? (
        <div id="header-bottom">
          <div id="header-menu-expansion">
            <div
              id="content-bar-expansion"
              onClick={() => setIsMenuExpand(!isMenuExpand)}
            >
              <div
                style={{
                  fontWeight: "1000",
                  textAlign: "center",
                  transform: `translateX(${transformX}%)`,
                  fontSize: "1.2rem",
                }}
              >
                {contentTitle}
              </div>
              {isMenuExpand && (
                <div
                  style={{
                    color: "#DC894E",
                    fontSize: "1.8rem",
                    marginLeft: "80px",
                  }}
                >
                  ▴
                </div>
              )}
              {!isMenuExpand && (
                <div
                  style={{
                    color: "#E2C067",
                    fontSize: "1.8rem",
                    marginLeft: "80px",
                  }}
                >
                  ▾
                </div>
              )}
            </div>

            {isMenuExpand && (
              <div id="content-header-expansion-container">
                <div id="content-header-mobile-expansion">
                  {config.isEnableLangSwitch && (
                    <div id="languageBtn-container">
                      <button
                        className="languageBtn"
                        onClick={buttonHandleEnglish}
                        style={{ display: isEng ? "none" : "" }}
                      >
                        Eng
                      </button>
                      <button
                        className="languageBtn"
                        onClick={buttonHandleChinese}
                        style={{ display: isEng ? "" : "none" }}
                      >
                        中文
                      </button>
                    </div>
                  )}
                  <div id="header-social-icon-container">
                    {links && links[0] && links[0].path !== "" && (
                      <a href={links[0].path} target="_blank">
                        <img
                          className="header-social-icon"
                          src={fbIcon}
                          alt=""
                        />
                      </a>
                    )}
                    {links && links[1] && links[1].path !== "" && (
                      <a href={links[1].path} target="_blank">
                        <img
                          className="header-social-icon"
                          src={igIcon}
                          alt=""
                        />
                      </a>
                    )}
                    {links && links[2] && links[2].path !== "" && (
                      <a href={links[2].path} target="_blank">
                        <img
                          className="header-social-icon"
                          src={twitterIcon}
                          alt=""
                        />
                      </a>
                    )}
                  </div>
                  {role ? (
                    <>
                      <div className="loginBtn-container">
                        <button onClick={handleOpen} className="login-button">
                          {nickname}
                        </button>
                        {openList && (
                          <div className="header-top-right-button-container">
                            <span />
                            <a href={lanString + "/user-profile"}>
                              <button>{isEng ? "Profile" : "個人資料"}</button>
                            </a>
                            {/* <a href="/login"> */}
                            <button onClick={handleLogout}>
                              {isEng ? "Logout" : "登出"}
                            </button>
                            {/* </a> */}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="loginBtn-container">
                      <a href={lanString + "/login"}>
                        <button
                          className="login-button"
                          style={{ textOverflow: "unset" }}
                        >
                          {isEng ? "Login" : "登入"}
                        </button>
                      </a>
                    </div>
                  )}
                </div>
                <hr className="hrColor"></hr>
                <div id="header-menu-container">
                  {menuTitles.map((menuTitles: any, index: number) => (
                    <CustomizedMenus
                      href={lanString + menuTitles.url}
                      name={
                        isEng
                          ? menuTitles.displayNameEng
                          : menuTitles.displayName
                        // menuTitles.title
                      }
                      lst={menuTitles.child}
                      boxPos={"right"}
                      key={index}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div id="header-bottom">
          <div id="header-menu-container">
            {menuTitles.map((menuTitles: any, index: number) => (
              <CustomizedMenus
                href={lanString + menuTitles.url}
                name={
                  isEng ? menuTitles.displayNameEng : menuTitles.displayName
                  // menuTitles.title
                }
                lst={menuTitles.child}
                boxPos={"right"}
                key={index}
                index={index}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Header2;
