import React, { useEffect, useState } from "react";
import "./Collaborator.scss";
import Footer from "../Footer/Footer";
import Card from "../Card/Card";
import "./Collaborator.css";
import SectionBar from "../SectionBar/SectionBar";
import cloud from "./Group 90.webp";
import Header2 from "../Header2/Header2";
import axios from "axios";
import config from "../config";
import API from "../helper/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

const cardContainerClass = "col-md-6 col-lg-4";

/**
 * Function to generate card UI.
 * @param numOfCard Number of card to generate.
 * @returns Card UI, in array of JSX.Element.
 */
const generateCards = (
  numOfCard: number,
  containerClass: string = "col-md-6 col-lg-4 card-container"
): JSX.Element[] => {
  // Prepare the array (return value)
  const cards: JSX.Element[] = [];
  // Loop via the target number
  for (let i in [...Array(numOfCard)]) {
    // The UI look like
    const card: JSX.Element = (
      <div className={containerClass} key={i}>
        {/* <Card /> */}
      </div>
    );
    // Store in the return value
    cards.push(card);
  }
  // Return to the caller
  return cards;
};

type cardDetails = {
  icon: string;
  fullName: string;
  fullNameEng: string;
  position: string;
  positionEng: string;
  education: string;
  educationEng: string;
  link: string;
  category: string;
  cardPosition: number;
};

function Collaborator() {
  let id = "Collaborators";

  const language = useSelector((state: RootState) => state.language.language);

  const [internationalList, setInternationalList] = useState([] as any);
  const [localList, setLocalList] = useState([] as any);
  const [loadingText, setLoadingText] = useState("Loading");
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  const containerClass: string = "collaborator-card-container";

  async function getData() {
    const request = `{childMenu(where: $where){
      id,
      title
      childContent {
          title,
          session,
          template,
          images {
              url,
              priority,
              id,
              category
          }
          id
          contents(where: $contentsWhere2) {
              category,
              content,
              id,
              priority,
              position,
              isDeleted,
              langLabel{
                id
                lang
                index
              }
          }
          sessionImage
      }
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
      variables: {
        where: { title: id },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
      },
    };
    axios({
      url: config.user_base_url,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(res.data.data.childMenu);
        // setPageInfo(res.data.data.childMenu);
        let receivedData = res.data.data.childMenu;
        let internationalArr: [cardDetails] = [] as any;
        let localArr: [cardDetails] = [] as any;
        if (res.data.data.childMenu.childContent[0]) {
          if (res.data.data.childMenu.childContent[0].contents.length > 0) {
            let idList = [] as any;
            receivedData.childContent[0].contents.forEach((e: any) => {
              idList.push({ id: e.category });
            });
            // console.log(idList);
            let totalList = idList.reduce((acc: any, curr: any) => {
              // console.log(acc);
              let key = `${curr.id}`;
              if (key in acc) {
                acc[key]++;
              } else {
                acc[key] = 1;
              }
              return acc;
            }, {});
            for (const [key, value] of Object.entries(totalList)) {
              if (key.split("_")[0] === "international") {
                internationalArr.push({
                  icon: "",
                  fullName: "",
                  fullNameEng: "",
                  category: key,
                  position: "",
                  positionEng: "",
                  education: "",
                  educationEng: "",
                  link: "",
                  cardPosition: 0,
                });
              } else if (key.split("_")[0] === "local") {
                localArr.push({
                  icon: "",
                  fullName: "",
                  fullNameEng: "",
                  category: key,
                  position: "",
                  positionEng: "",
                  education: "",
                  educationEng: "",
                  link: "",
                  cardPosition: 0,
                });
              }
            }
            receivedData.childContent[0].contents.forEach((e: any) => {
              internationalArr.forEach((international: any, idx: number) => {
                if (e.category === international.category) {
                  if (
                    e.position === "fullName" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    internationalArr[idx]["fullName"] = e.content;
                  } else if (
                    e.position === "position" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    internationalArr[idx]["position"] = e.content;
                  } else if (
                    e.position === "education" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    internationalArr[idx]["education"] = e.content;
                  } else if (e.position === "link") {
                    internationalArr[idx]["link"] = e.content;
                  }
                  // English version
                  else if (
                    e.position === "fullNameEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    internationalArr[idx]["fullNameEng"] = e.content;
                  } else if (
                    e.position === "positionEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    internationalArr[idx]["positionEng"] = e.content;
                  } else if (
                    e.position === "educationEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    internationalArr[idx]["educationEng"] = e.content;
                  } else if (e.position === "cardPosition") {
                    internationalArr[idx]["cardPosition"] = +e.content;
                  }
                }
              });
              localArr.forEach((local: any, idx: number) => {
                if (e.category === local.category) {
                  if (
                    e.position === "fullName" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    localArr[idx]["fullName"] = e.content;
                  } else if (
                    e.position === "position" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    localArr[idx]["position"] = e.content;
                  } else if (
                    e.position === "education" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    localArr[idx]["education"] = e.content;
                  } else if (e.position === "link") {
                    localArr[idx]["link"] = e.content;
                  }
                  // English version
                  else if (
                    e.position === "fullNameEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    localArr[idx]["fullNameEng"] = e.content;
                  } else if (
                    e.position === "positionEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    // console.log("positionLocal", e);
                    localArr[idx]["positionEng"] = e.content;
                  } else if (
                    e.position === "educationEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                  } else if (e.position === "cardPosition") {
                    localArr[idx]["cardPosition"] = +e.content;
                  }
                }
              });
            });
            // console.log(receivedData.childContent[0]);
            receivedData.childContent[0].images.forEach((e: any) => {
              internationalArr.forEach((international: any, idx: number) => {
                if (e.category === international.category) {
                  internationalArr[idx]["icon"] = `${API.getImage}${e.url}`;
                }
              });
              localArr.forEach((local: any, idx: number) => {
                if (e.category === local.category) {
                  localArr[idx]["icon"] = `${API.getImage}${e.url}`;
                }
              });
            });
          }
        }
        // console.log(internationalArr);
        // console.log(localArr);
        internationalArr.sort((a, b) => +a.cardPosition - +b.cardPosition);
        localArr.sort((a, b) => +a.cardPosition - +b.cardPosition);
        setInternationalList(internationalArr);
        setLocalList(localArr);
      })
      .catch((err) => console.error("err", err));
  }

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mobile scroll header control
  // const isPhone = window.matchMedia("(max-width: 768px)").matches;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const phone = window.matchMedia("(max-width: 768px)").matches;
  //     let contenty: number = 159.19;
  //     let content = document.querySelector(
  //       ".collaborator-content-section"
  //     ) as HTMLElement;
  //     const currentScrollPos = window.pageYOffset;
  //     const collaboratorContentContainers = document.getElementsByClassName(
  //       "collaborator-content-container"
  //     );

  //     if (phone) {
  //       if (content)
  //         contenty = window.scrollY + content.getBoundingClientRect().top; // Y
  //       for (let i = 0; i < collaboratorContentContainers.length; i++) {
  //         const collaboratorContentContainer = collaboratorContentContainers[
  //           i
  //         ] as HTMLElement;
  //         if (currentScrollPos >= contenty && collaboratorContentContainer) {
  //           collaboratorContentContainer.style.marginTop = "17rem";
  //         } else if (collaboratorContentContainer) {
  //           collaboratorContentContainer.style.marginTop = "2rem";
  //         }
  //       }
  //     } else {
  //       for (let i = 0; i < collaboratorContentContainers.length; i++) {
  //         const collaboratorContentContainer = collaboratorContentContainers[
  //           i
  //         ] as HTMLElement;
  //         collaboratorContentContainer.style.marginTop = "-19rem";
  //       }
  //     }
  //   };

  //   // Add scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Trigger the scroll event on page load
  //   handleScroll();

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isPhone, []]);
  useEffect(() => {
    getData();
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "關於合作者 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "Our Collaborators - ACTuwise"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (internationalList.length && localList.length) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [internationalList, localList]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="wrapper">
      <Header2
        url="/about-us/our-collaborators" /*onLanguageChange={handleLanguageChange}*/
      />
      <div className="collaborator-content-section">
        <SectionBar
        // sections={[
        //   { name: "Meet Us", url: "/meetus" },
        //   { name: "Team In Charge", url: "/meetus/teamhead" },
        //   { name: "About Collaborator", url: "/meetus/collaborators" },
        //   {
        //     name: "Our Counsellor & Team Member",
        //     url: "/meetus/counsellors",
        //   },
        // ]}
        />

        <div className="collaborator-content-container">
          <div id="collaborator-title">
            <img
              id="cloud"
              src={cloud}
              alt=""
              style={{ visibility: "hidden" }}
            />
            <h1 id="about-title">
              {language === "en" ? "Our Collaborators" : "關於合作者"}
            </h1>{" "}
            {/* Add this */}
            <img id="cloud" src={cloud} alt="" />
          </div>
          {/* <div className="my-3"> */}
          {/* <div className="container"> */}
          <div className="row card-container" id="profile">
            <h2 className="word1">
              {language === "en" ? "International Collaborators" : "國際合作者"}
            </h2>
            {internationalList.map((e: any, idx: number) => {
              return (
                <div className={containerClass} key={idx}>
                  <Card cardDetail={e} language={language} key={idx} />
                </div>
              );
            })}
          </div>
          {/* </div> */}
          {/* <div className="container"> */}
          <div className="row card-container" id="profile">
            <h2 className="word2">
              {language === "en" ? "Local Collaborators" : "本地合作者"}
            </h2>
            {localList.map((e: any, idx: number) => {
              return (
                <div className={containerClass} key={idx}>
                  <Card cardDetail={e} language={language} key={idx} />
                </div>
              );
            })}
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Collaborator;
